import CouponModel from './models/CouponModel'
import CouponService from './services/CouponService'
import createListener from '@flamingo_tech/funkgo/src/utils/createListener'
import retry from '@flamingo_tech/funkgo/src/utils/retry'
import { getSiteStation } from '../../utils/siteUtils'
import { getCouponDiscountPercentage } from '../../utils/couponUtils'

import { debounce } from 'debounce'

/* ----------------------------------------------- */

const COUPON_UPDATE_DEBOUNCE = 200
const COUPON_HUB_STORAGE_KEY = 'cc'
const COUPON_CENTER_ID_STORAGE_KEY = 'cci'

/* ----------------------------------------------- */

export default class CouponHub {
  constructor({ pluginHub }) {
    if (typeof window === 'undefined') {
      throw new Error('[CouponHub] should not running on server side')
    }

    this.pluginHub = pluginHub
    this.$logger = pluginHub.getLogger().createLogger('CouponHub')

    const $storage = this.pluginHub.getStorage()
    this.couponCenterStorage = $storage.create(COUPON_CENTER_ID_STORAGE_KEY)

  }

  start() {
    this.initListener()
    this.initCouponCenter()
  }

  /* ----------------------------------------------- */

  initListener() {
    const {
      subscribe,
      unsubscribe,
      notify
    } = createListener()

    this.subscribe = subscribe
    this.unsubscribe = unsubscribe
    this.notify = notify
  }

  /* ----------------------------------------------- */

  getLegacyAvailableCoupons() {
    const $storage= this.pluginHub.getStorage()
    const localDb = $storage.create(COUPON_HUB_STORAGE_KEY)

    const { registeredCoupon, takenCoupon } = localDb.getItem({
      registeredCoupon: {},
      takenCoupon: {}
    })

    const allCoupons = Object.keys(registeredCoupon).map(couponId =>
      new CouponModel({
        id: couponId,
        meta: registeredCoupon[couponId],
        userOptions: takenCoupon[couponId]
      })
    )

    return this.prioritizeCoupons(allCoupons.filter(
      coupon => this.isCouponAvailable(coupon)
    ))

  }

  /*
  优先级如下:
  1. 主推的券
  2. 非主推的券
    全场通用券 -> 场次券
    全场通用券按折扣力度进行排列; 场次券按领取时间逆序排列，即最近领取的在最前面
*/
prioritizeCoupons(coupons) {
  if (coupons.length <= 1) {
    return coupons
  }

  // filter highlight coupons as first tier
  const highlightedCoupons = coupons
    .filter(coupon => coupon.userOptions.highlight)
    .sort((a, b) => a.userOptions.highlightIndex - b.userOptions.highlightIndex)


  const unHighlightedCoupons = coupons.filter(coupon => !coupon.userOptions.highlight)

  const allTargetedCoupons = unHighlightedCoupons
    .filter(coupon => coupon.meta.targetType === 'ALL')
    .sort((a, b) => getCouponDiscountPercentage(b) - getCouponDiscountPercentage(a))

  const collectionTargetedCoupons = unHighlightedCoupons
    .filter(coupon => coupon.meta.targetType !== 'ALL')
    .sort((a, b) => b.userOptions.takenTime - a.userOptions.takenTime)

  return highlightedCoupons.concat(allTargetedCoupons, collectionTargetedCoupons)
}

  /* ----------------------------------------------- */

  getCouponCenter = () => {
    return this.couponCenterStorage.getItem({})[getSiteStation().id] || {}
  }

  setCouponCenter = ({ id, coupons = [] }) => {
    const couponCenterStorageItem = this.couponCenterStorage.getItem({})

    this.couponCenterStorage.setItem(Object.assign({}, couponCenterStorageItem, {
      [getSiteStation().id]: {
        id,
        coupons
      }
    }))
  }

  getCouponCenterId = () => {
    return this.getCouponCenter().id
  }

  initCouponCenter() {
    const $http = this.pluginHub.getHttpClient()

    this.couponService = new CouponService($http)

    const $detector = this.pluginHub.getDetector()
    if ($detector && $detector.isApp()) {
      return
    }

    const couponCenterId = this.getCouponCenter().id

    if (couponCenterId) {
      this.ensureCouponCenterProcess = this.connect(couponCenterId).catch(err => {
        throw err
      }).then(data => {
        this.setCouponCenter(data)
        this.notifyCouponUpdated()
      })
    }
  }

  connectCouponCenter() {
    const $http = this.pluginHub.getHttpClient()

    this.couponService = new CouponService($http)

    const couponCenterId = this.getCouponCenter().id

    let connectProcess

    if (couponCenterId) {
      connectProcess = this.connect(couponCenterId).catch(err => {
        throw err
      })
    } else {
      // sync legacy local coupons
      connectProcess = this.create(this.getLegacyAvailableCoupons())
    }

    this.ensureCouponCenterProcess = connectProcess
      .then(data => {
        this.setCouponCenter(data)
        this.notifyCouponUpdated()
      })

    return this.ensureCouponCenterProcess
  }

  ensureCouponCenterCreated() {
    return this.ensureCouponCenterProcess
    ? Promise.resolve(this.ensureCouponCenterProcess)
    : this.connectCouponCenter()
  }

  replaceCouponCenter = couponCenterId => {
    if (!couponCenterId) {
      return Promise.reject(new Error('[CouponHub] must specific coupon center id'))
    }

    if (couponCenterId === this.getCouponCenterId()) {
      return Promise.resolve(false)
    }

    this.setCouponCenter({ id: couponCenterId })
    return this.connect(couponCenterId).then(data => {
      this.setCouponCenter(data)
      this.notifyCouponUpdated()
      return Promise.resolve(true)
    }).catch(err => {
      return Promise.reject(err)
    })
  }

  /* ----------------------------------------------- */

  create(legacyCoupons) {
    const request = () => this.couponService.applyCouponCenter(legacyCoupons)

    return this.callWithRetry(request, 'create')
  }

  connect(id) {
    const request = () => this.couponService.getCouponCenter(id)

    return this.callWithRetry(request, 'connect')
  }

  /* ----------------------------------------------- */
  refreshDb() {
    // re-init remote coupon db to receive changes from other webview
    return this.connectCouponCenter()
  }


  notifyCouponUpdated = debounce(() => {
    this.notify(
      this.getAvailableCoupons()
    )
  }, COUPON_UPDATE_DEBOUNCE)


  getAvailableCoupons() {
    const { coupons = []} = this.couponCenterStorage.getItem({})[getSiteStation().id] || {}

    return coupons
  }

  /* ----------------------------------------------- */

  getAvailableCoupon(couponId) {
    return this.getAvailableCoupons().filter(coupon => coupon.id === couponId)[0]
  }

  getAvailableCouponsByCode(couponCode) {
    return this.getAvailableCoupons().filter(coupon => this.getCouponCode(coupon) === couponCode)
  }

  isCouponEqual(a, b) {
    return a && b && this.getCouponId(a) === this.getCouponId(b)
  }

  isCouponAvailable(coupon) {
    if (!this.isCouponMetaAvailable(coupon.meta)) {
      return false
    }

    return true
  }

  isCouponMetaAvailable(couponMeta) {
    if (!couponMeta || (couponMeta.clientEndDate && new Date(couponMeta.clientEndDate) - new Date() <= 0)) {
      return false
    } else {
      return true
    }
  }


  /* ---------------------------------- */
  pickBestForProduct(product) {
    const availableCoupons = this.getAvailableCoupons()
    let bestCoupon = null

    for (let i = 0; i < availableCoupons.length; i++) {
      const coupon = availableCoupons[i]

      if (coupon.meta.targetType === 'ALL') {
        bestCoupon = coupon
        break
      } else {
        const targetCategories = (coupon.meta.targetSelection || []).map(selection => selection.category)

        if (targetCategories.indexOf(product.category) > -1) {
          bestCoupon = coupon
          break
        }
      }
    }

    return bestCoupon
  }

  //   only need to return global at this time (for wheel)
  pickBestForEvent(
    {
      type, // salesEvent | collection | home | searchResult
      handle // handle or null
    } = {}
  ) {
    const availableCoupons = this.getAvailableCoupons()

    return availableCoupons.filter(coupon => coupon.meta.targetType === 'ALL')[0]
  }

  /* ---------------------------------- */

  getCouponId(coupon) {
    if (!coupon.id) {
      throw new Error(`[CouponHub] not provided coupon id`)
    }

    return coupon.id
  }

  getCouponName(coupon) {
    const couponId = coupon && coupon.id
    const couponCode = coupon && this.getCouponCode(coupon)

    if (couponId && couponCode) {
      return `${couponId} (${couponCode})`
    }

    return couponId || couponCode || 'N/A'
  }

  getCouponCode(coupon) {
    return coupon.meta && coupon.meta.discount && coupon.meta.discount.code
  }


  getShoppingCartId = () => {
    const storePlugin = this.pluginHub.getPlugin('store')

    return storePlugin.getCurrentCartId()
  }


  // public api for coupon update
  /* ---------------------------------- */

  highlight(availablePromotions = []) {

    if (availablePromotions.length > 0) {
      const highlightCoupons = availablePromotions.map(coupon => ({
        ...coupon,
        highlight: true
      }))

      this.batchTake(highlightCoupons)
    }

  }

  batchTake(couponMetaList) {
    return this.ensureCouponCenterCreated().then(() => {
      const couponCenterId = this.getCouponCenter().id

      const couponsToTake = couponMetaList.filter(coupon  => coupon.id)

      return this.couponService.takeCoupons(couponCenterId, couponsToTake, { shoppingCartId: this.getShoppingCartId() }).then(data => {
        this.setCouponCenter(data)
        this.notifyCouponUpdated()
        this.refreshAppCouponCenter()
      })
    })
  }

  take(couponMeta) {
    return this.ensureCouponCenterCreated().then(() => {
      const couponCenterId = this.getCouponCenter().id

      return this.couponService.takeCoupons(couponCenterId, [couponMeta], { shoppingCartId: this.getShoppingCartId() }).then(data => {
        this.setCouponCenter(data)
        this.notifyCouponUpdated()
        this.refreshAppCouponCenter()
      })
    })
  }


  takeCouponCodes(couponCodeList) {
    if (couponCodeList.length > 0) {
      return this.ensureCouponCenterCreated().then(() => {
        const couponCenterId = this.getCouponCenter().id

        return this.couponService.takeCouponCodes(couponCenterId, couponCodeList, { shoppingCartId: this.getShoppingCartId() }).then(data => {
          this.setCouponCenter(data)
          this.notifyCouponUpdated()
          this.refreshAppCouponCenter()
        })
      })
    } else {
      return Promise.resolve()
    }
  }

  syncCouponCenterToRemote() {
    return this.ensureCouponCenterCreated().then(() => {
      const couponCenterId = this.getCouponCenter().id

      return this.couponService.syncCouponCenter(couponCenterId, { shoppingCartId: this.getShoppingCartId() }).then(data => {
        this.setCouponCenter(data)
        this.notifyCouponUpdated()
        this.refreshAppCouponCenter()
      })
    })
  }

  refreshAppCouponCenter() {
    const $detector = this.pluginHub.getDetector()
    if ($detector && $detector.isApp()) {
      const $bridge = this.pluginHub.getPlugin('bridge')
      if ($bridge) {
        $bridge.refreshCouponCenter()
      }
    }
  }

  refresh() {
    return this.refreshDb()
  }

  /* ---------------------------------- */
  $trackEvent(action, label, nonInteraction = true) {
    if (this.pluginHub.getPlugin('tracker')) {
      const $tracker = this.pluginHub.getPlugin('tracker')

      $tracker.event({
        category: 'Coupon',
        action,
        label,
        nonInteraction
      })
    }
  }

  trackCoupon(coupon, action, label, nonInteraction) {
    this.$trackEvent(`${action}_${this.getCouponName(coupon)}`, label, nonInteraction)
  }

  trackView(coupon) {
    this.trackCoupon(coupon, 'view', undefined, true)
  }

  trackTake(coupon) {
    this.trackCoupon(coupon, 'take', undefined, true)
  }

  trackCancel(coupon) {
    this.trackCoupon(coupon, 'cancel', undefined, false)
  }

  /* ----------------------------------------------- */
  trackError(error, label, isFatal = false) {
    const $tracker = this.pluginHub.getPlugin('tracker')

    if ($tracker) {
      $tracker.error({
        category: 'Coupon',
        isFatal,
        error,
        label
      })
    }
  }

  trackErrorFatal(error, label) {
    this.trackError(error, label, true)
  }

  callWithRetry(request, mode) {
    const onError = ({ error, currentCount }) => {
      this.trackError(`${error.message} (retry:${currentCount})`, mode)
    }

    const retryMaxCount = 3
    const retryPattern = /(timeout)|(network)|(404)/i

    return retry(request, onError, retryMaxCount, retryPattern).catch(error => {
      this.trackErrorFatal(error, mode)
      throw error
    })
  }
}
