import CollectService from './CollectService'
import { v4 as uuidv4 } from 'uuid'

const COLLECT_INSTANT_DEBOUNCE = 20
const COLLECT_DEBOUNCE = 500
const MAX_RETRY_COUNT = 3
const RETRY_DURATION = [1000, 2000, 3000]
const DEVICE_INFO_STORAGE = 'ndi'
const RESOURCE_LOCATOR_STORAGE = 'rls' // 资源位
const TIME_ZONE_STORAGE = 'tzs' // timezone session storage
const COLLECT_API = '/data/process/event/track'
const CART_ID_STORAGE = 'sdc'
const COUPON_CENTER_ID_STORAGE = 'cci'
const SESSION_ID = 'si'


export default class CollectClient {
  constructor({ pluginHub, isProductionEnv }) {
    this.pluginHub = pluginHub
    this.isProductionEnv = isProductionEnv
    this.$service = new CollectService(pluginHub.getHttpClient(), isProductionEnv)
    this.$logger = pluginHub.getLogger().createLogger('CollectClient')
    this.$detector = pluginHub.getDetector()
    this.$deviceKey = pluginHub.getPlugin('deviceKey')
    this.resourceLocatorStorage = this.pluginHub.getStorage().create(RESOURCE_LOCATOR_STORAGE, { strategy: 'SESSION'})
    this.timezoneStorage = this.pluginHub.getStorage().create(TIME_ZONE_STORAGE, { strategy: 'SESSION'})

    this.resourceLocator = {}
    this.deviceKey = ''
    this.queue = []
    this.debounceQueue = []
    this.retryQueue = []
    this.ready = false
    this.retryCount = 0
    this.isRetryProcessing = false
    this.collectAPI = COLLECT_API
    this.initContext()
  }

  /* ------------------------------------- */

  initContext() {
    this.$deviceKey.getDeviceKey().then(dk => {
      this.readyToCollect(dk)
    })
  }

  /* ------------------------------------- */
  getDeviceKey() {
    return this.deviceKey
  }

  getContext() {
    const context = {
      deviceKey: this.getDeviceKey(),
      timezone: this.getTimezone()
    }

    const $detector = this.pluginHub.getDetector()

    Object.assign(context, {
      clientId: $detector.getClientId(),
      clientVersion: $detector.getClientVersion(),
    })

    const $user = this.pluginHub.getPlugin('user')
    const userToken = $user ? $user.getToken() : null

    if (userToken) {
      Object.assign(context, {
        userId: userToken.id,
        accessToken: userToken.accessToken,
      })
    }

    const $storage = this.pluginHub.getStorage()
    const $site = this.pluginHub.getPlugin('site')

    if ($storage && $site) {
      const site = $site.getSiteInfo()
      const shoppingCartId = ($storage.create(CART_ID_STORAGE).getItem() || {})[site.id]
      const couponCenterId = (($storage.create(COUPON_CENTER_ID_STORAGE).getItem() || {})[site.id] || {}).id
      const sessionId = $storage.create(SESSION_ID, { strategy: 'SESSION'}).getItem(uuidv4())

      Object.assign(context, {
        shoppingCartId,
        couponCenterId,
        sessionId
      })
    }

    return context
  }

  getTimezone() {
    if (this.timezoneStorage.getItem() === undefined) {
      const timezone = Math.floor(0 - (new Date().getTimezoneOffset() / 60))

      this.timezoneStorage.setItem(timezone)

      return timezone
    } else {
      return this.timezoneStorage.getItem()
    }
  }

  readyToCollect(deviceKey) {
    this.deviceKey = deviceKey
    this.ready = true

    setTimeout(() => {
      this.updatePushToken()
      this.updateUserId()

      const $user = this.pluginHub.getPlugin('user')

      if ($user) {
        $user.observeUser(() => this.updateUserId())
      }
    }, 2000)
  }

  /* ------------------------------------- */

  getTrackHeaders() {
    const $detector = this.pluginHub.getDetector()
    const $storage = this.pluginHub.getStorage()
    const $site = this.pluginHub.getPlugin('site')
    const headers = {}

    if ($site) {
      const site = $site.getSiteInfo()
      headers['x-client-locale'] = ($detector.isServer() && this.pluginHub.options && this.pluginHub.options._locale) || site.locale
    }
    if ($storage) {
      const sourceLocationStorage = $storage.create('source_location', { strategy: 'SESSION' })
      headers['x-client-source-location'] = sourceLocationStorage.getItem(window.location.href)
    }
    return headers
  }

  updatePushToken() {
    const $bridge = this.pluginHub.getPlugin('bridge')

    if ($bridge && $bridge.isAppBridgesEnabled()) {
      $bridge.getPushToken().then(pushToken => {
        if (typeof pushToken === 'string' && pushToken.length) {
          this.mixinDeviceInfo('pushToken', pushToken)
        } else {
          this.$logger.error(new Error(`invalid PushToken: ${JSON.stringify(pushToken)}`), 'updatePushToken')
        }
      }).catch(err => undefined)
    }
  }

  getUserId() {
    const $user = this.pluginHub.getPlugin('user')
    if ($user && $user.getToken()) {
      const { id } = $user.getToken()
      return id
    }
    return undefined
  }

  updateUserId() {
    const userId = this.getUserId()

    if (userId) {
      this.mixinDeviceInfo('userId', userId)
    }
  }

  /* ------------------------------------- */

  mixinDeviceInfo(key, value) {
    const $storage = this.pluginHub.getStorage()
    const deviceInfoStorage = $storage.create(DEVICE_INFO_STORAGE)

    const deviceInfo = deviceInfoStorage.getItem({})

    const existsValue = deviceInfo[key]

    if (typeof value === 'string' && value.length && value !== existsValue) {
      const payload = {
        [key]: value
      }

      this.$service.updateDeviceInfo(this.getDeviceKey(), payload).then(
        () => {
          deviceInfoStorage.setItem({
            ...deviceInfoStorage.refreshItem(),
            [key]: value,
          })
        },
        err => undefined // api call failed will be track automatically, no need to track here
      )
    }
  }

  /* ------------------------------------- */
  sendQueue(payload) {
    if (!this.ready) {
      return Promise.reject(new Error('collect client is not ready'))
    }

    return this.$service.collect(payload, this.getContext())
  }

  flushQueue = () => {
    const queue = [...this.queue, ...this.debounceQueue]

    if (queue && this.ready) {
      const payload = queue
      this.queue = []
      this.debounceQueue = []
      return this.sendQueue(payload)
    } else {
      return Promise.resolve()
    }
  }

  /* ------------------------------------- */
  flush() {
    return this.flushQueue()
  }

  push(event) {
    const resourceLocator = this.getResourceLocators()

    const eventParams = {
      ...event,
      channel: resourceLocator
    }

    if (event.isDebounce) {
      this.pushDebounceEvent(eventParams)
    } else {
      this.pushInstantEvent(eventParams)
    }
  }

  pushInstantEvent(event) {
    this.queue.push(event)

    if (!this.instantTimer) {
      this.instantTimer = setTimeout(() => {
        if (this.queue.length > 0 && this.ready) {
          const payload = this.queue
          this.queue = []

          this.sendQueue(payload).catch(() => {
            this.retryQueue = this.retryQueue.concat(payload)
            this.triggerRetry()
          })
        }
        this.instantTimer = null
      }, COLLECT_INSTANT_DEBOUNCE)
    }
  }

  pushDebounceEvent(event) {
    this.debounceQueue.push(event)

    if (!this.debounceTimer) {
      this.debounceTimer = setTimeout(() => {
        if (this.debounceQueue.length > 0 && this.ready) {
          const payload = this.debounceQueue
          this.debounceQueue = []

          this.sendQueue(payload).catch(() => {
            this.retryQueue = this.retryQueue.concat(payload)
            this.triggerRetry()
          })
        }
        this.debounceTimer = null
      }, COLLECT_DEBOUNCE)
    }
  }


  processRetry() {
    if (this.retryQueue.length > 0) {

      this.isRetryProcessing = true
      this.retryCount++

      const duration = RETRY_DURATION[this.retryCount - 1]

      setTimeout(() => {
        const payload = this.retryQueue
        this.retryQueue = [] // sync clean queue first

        this.sendQueue(payload).then(() => {
          // retry successfully just reset
          this.retryCount = 0
          this.isRetryProcessing = false
        }).catch(() => {
          if (this.retryCount === MAX_RETRY_COUNT) { // if hit MAX_RETRY_COUNT, reset to 0
            this.retryCount = 0
            this.isRetryProcessing = false
            // always retry once since there might be pending retry events
            this.triggerRetry()
          } else {
            // put it back to retryQueue
            this.retryQueue = [...payload, ...this.retryQueue]
            this.processRetry()
          }
        })
      }, duration)
    }
  }



  triggerRetry() {
    if (!this.isRetryProcessing) {
      this.processRetry()
    }
  }

  getResourceLocators() {
    const { main = '', sub = ''} = this.resourceLocator

    let result

    if (!main && !sub) {
      const { main = '', sub = '' } = this.resourceLocatorStorage.getItem({})

      return `${main},${sub}`
    } else {
      result = `${main},${sub}`
    }

    return result
  }

  setResourceLocators(main, sub) {
    if (main) {
      this.resourceLocator.main = main
      this.resourceLocator.sub = '' // reset sub resource locator

      this.resourceLocatorStorage.setItem(this.resourceLocator)
    }

    if (sub) {
      this.resourceLocator.sub = sub

      this.resourceLocatorStorage.setItem(this.resourceLocator)
    }

  }

  notifyFBTrack(action, params, eventID) {
    return this.$service.notifyFBTrack(action, params, eventID)
  }
}