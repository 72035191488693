import React, { useState, useContext, useEffect, forwardRef } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import ProductSection from './ProductSection'
import ProductCommentCard from './ProductCommentCard'
import ProductCommentSectionHeader from './ProductCommentSectionHeader'
import ErrorPage, {NotFoundPage} from "../../common/ErrorPage/ErrorPage"
import Image from '../../common/Image/Image'
import useFn from '../../../hooks/useFn'
import { withDrawer } from '../../common/Drawer/Drawer'
import SingleColumn from '../../common/List/SingleColumn'
import HorizontalColumns from '../../common/List/HorizontalColumns'
import CommentImagePreviewer from './CommentImagePreviewer'
import { generateCommentImages } from '../../../utils/Store/commentUtils'
import styles from './ShowAndReviewSection.module.css'
import cx from '../../../utils/className'

const postItemIdPrefix = 'PostItem-'

const PostImage = props => {
  const title = props.userName ? `@${props.userName}` : null
  const imageTitle = (title && props.productTitle) ? `${title}’s show of ${props.productTitle} - thumbnail` : null
  const clickImage = () => {
    props.onClick(props.index)
  }
  return (
    <div className={cx(styles.postWrapper, styles.box)}>
      <Image
        src={props.src}
        title={imageTitle}
        middle
        lazy
        objectFit='cover'
        placeholder={{ width: 109, height: 165 }}
        className={styles.image}
        onClick={clickImage}
      />
    </div>
  )
}

const PostPreviewImageItem = props => {
  const title = props.userName ? `@${props.userName}` : null
  const imageTitle = (title && props.productTitle) ? `${title}’s show of ${props.productTitle}` : null
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  return (
    <div className={cx(styles.postWrapper, isDesktop && styles.inDesktop)}>
      <Image
        src={props.src}
        title={imageTitle}
        large
        lazy={props.lazy !== undefined ? props.lazy : true}
        objectFit='cover'
        placeholder={{ width: 375, height: 498 }}
        className={styles.previewImage}
        onClick={props.onClick}
      />
    </div>
  )
}


const PostPreviewCard = ({ imageProps }) => {
  const bindId = `${postItemIdPrefix}${imageProps.id}`
  return (
    <div className={styles.item} id={bindId}>
      <PostPreviewImageItem {...imageProps} />
      {
        imageProps.desc ?
          <div className={styles.content}>
            {
              imageProps.userName && <span>{`@${imageProps.userName}  `}</span>
            }
            {imageProps.desc}
          </div>
          :
          <div className={styles.noContent} />

      }
    </div>
  )
}

const PostImageContent = (props) => {
  const { initPreviewIndex, posts, productTitle, scrollRef, onPostsReachEnd } = props
  const plugin = useContext(PluginContext)
  const isDesktop = plugin.$detector.isDesktop()

  const shows = posts.map((post, index) => {
    const imageProps = {
      src: post.mainImage,
      userName: post.userInfo.name,
      productTitle: productTitle,
      subtitle: post.skuTitle,
      desc: post.desc,
      lazy: initPreviewIndex !== index,
      id: post.id,
    }

    return { imageProps }
  })

  const initScroll = () => {
    if (initPreviewIndex !== 0) {
      scrollToPreviewItem()
    }
  }

  const withUseInitScroll = useFn(initScroll)

  useEffect(() => {
    withUseInitScroll()
  }, [withUseInitScroll])

  const scrollToPreviewItem = () => {
    setTimeout(() => {
      if (scrollRef && scrollRef.current) {
        const previewItem = posts[initPreviewIndex]
        const bindIdSelector = `${postItemIdPrefix}${previewItem.id}`
        const previewChild = document.getElementById(bindIdSelector)
        if (isDesktop) {
          const contentElement = scrollRef.current.childNodes[0]
          const headerElement = scrollRef.current.parentNode.childNodes[0]
          contentElement.scrollTo(0, previewChild.offsetTop - headerElement.offsetHeight)
        } else {
          const contentElement = scrollRef.current
          contentElement.scrollTo(0, previewChild.offsetTop - 42)
        }
      }
    }, 0)
  }

  return (
    <div ref={scrollRef} className={cx(styles.postImageContent, isDesktop && styles.inDesktop)}>
      <SingleColumn
        childIs={PostPreviewCard}
        childItems={shows}
        onReachEnd={onPostsReachEnd}
      />
    </div>
  )
}

export const PostImageContentModal = withDrawer(PostImageContent, { fullScreen: true })

const ReviewsContent = (props) => {
  const { comment, onCommentsReachEnd, scrollRef, onClickCommentImage, isFetchingComments, showReviewsContent } = props
  const comments = comment.comments
  const [pageNum, setPageNum] = useState(1)

  const onReachEnd = () => {
    setPageNum(pre => pre + 1)
    onCommentsReachEnd(pageNum)
  }

  useEffect(() => {
    if (showReviewsContent) {
      onReachEnd()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[showReviewsContent])

  return (

    <div ref={scrollRef} className={styles.reviewsContent}>
      {
          (!isFetchingComments && comments && comments.length === 0) ?
          <NotFoundPage
            slotName="NotFoundPage"
            error={null}
            title={props.$i18n.transl('core.product.noResultCommit')}
            btn={null}
            type={ErrorPage.TYPE.NO_SEARCH_RES}
            className={styles.noSearchRes}
          ></NotFoundPage>:
          <SingleColumn
            childIs={ProductCommentCard}
            childItems={comments}
            childOptions={{
              className: styles.wrapper,
              nicknameClass: styles.nickname,
              headerClass: styles.header,
              descClass: styles.desc,
              contentClass: styles.content,
              contentWrapperClass: styles.contentWrapper,
              showAvaterName: true,
              onClickCommentImage
            }}
            onReachEnd={onReachEnd}
          />
      }
    </div>
  )
}

const ReviewsHeader = (props) => {
  let { onClose, headerTitle } = props

  return (
    <div className={styles.reviewsHeaderWrapper}>
      <div className={styles.reviewsHeaderNav}>
        {headerTitle}
        <span className={styles.closeIcon} onClick={onClose}>&#xe60e;</span>
      </div>
      <ProductCommentSectionHeader {...props} />
    </div>
  )
}


const ReviewsContentModal = withDrawer(ReviewsContent, { fullScreen: true, headerSetting: { HeaderComponent : ReviewsHeader }, bodyClass: styles.drawerBodyClass })

const ShowAndReviewSection = (props, ref) => {
  const plugin = useContext(PluginContext)
  const { comment, title: productTitle, posts, handle, onPostsReachEnd, product } = props
  const hasImages = posts.length > 0
  const hasReviews = comment.showReviewsContent && comment.totalCount > 0
  const hasFirstReview = comment.firstComments.length > 0

  const [showImagesContent, setShowImagesContent] = useState(false)
  const [previewImageIndex, setPreviewImageIndex] = useState(0)
  const [showReviewsContent, setShowReviewsContent] = useState(false)
  const [showCommentImages, setShowCommentImages] = useState(false)
  const [commentImageIndex, setCommentImageIndex] = useState(0)

  const commentImages = generateCommentImages(comment.comments.length > 0 ? comment.comments : comment.firstComments)

  const onCloseImagesContent = () => {
    setShowImagesContent(false)
  }
  const onCloseReviewsContent = () => {
    setShowReviewsContent(false)
    props.closeCommentPage()
  }

  const handleImageClick = (index) => {
    plugin.$track.event('Product', 'click_see_more_shows', handle)
    setShowImagesContent(true)
    setPreviewImageIndex(index)
  }

  const handleReviewClick = () => {
    plugin.$track.event('Product', 'click_see_more_views', handle)
    setShowReviewsContent(true)
  }

  ref.current = setShowReviewsContent

  const shows = posts.map((post) => {
    const props = {
      src: post.mainImage,
      userName: post.userInfo.name,
      productTitle: productTitle,
      subtitle: post.skuTitle,
      desc: post.desc,
      id: post.id,
    }
    return props
  })

  const handleClickAddToCart = params => {
    plugin.$track.event('Product', 'click_review_cart', handle)

    props.onAddToCartClick(params)
  }


  const handleClickCommentImage = image => {
    plugin.$track.event('Product', 'click_review_image', handle)

    let result = 0

    for (let i = 0; i < commentImages.length; i++) {
      if (commentImages[i].src === image) {
        result = i
        break
      }
    }
    setShowCommentImages(true)
    setCommentImageIndex(result)
  }

  const renderReview = () => {

    if (hasReviews || hasFirstReview) {
      const reviewItems = comment.firstComments.slice(0, 2)
      const hasMore = comment.overallCount > 2
      let moreText = ''
      if (hasMore) {
        moreText = plugin.$i18n.transl('core.product.seeAllReviews', { count: comment.overallCount })
      }

      return (
        <div className={styles.review}>
          {
            reviewItems?.length && reviewItems.map((reviewItem, i) => (
              <div className={styles.reviewItemsWrapper} key={reviewItem.id}>
                <ProductCommentCard
                  {...reviewItem}
                  nicknameClass={styles.nickname}
                  headerClass={styles.header}
                  descClass={styles.desc}
                  contentClass={cx(styles.content, hasMore && styles.contentLimit)}
                  contentWrapperClass={styles.contentWrapper}
                  className={cx(styles.reviewItemsClass, i<(reviewItems.length-1) && styles.reviewItemBottom)}
                  showAvaterName
                  onClickCommentImage={handleClickCommentImage}
                />
              </div>
            ))
          }
          {
            hasMore &&
            <>
              <div className={styles.seeAllReviews} onClick={handleReviewClick}>{moreText}</div>
              <div className={styles.bottomLine}></div>
            </>
          }
        </div>
      )
    }
    return <div className={styles.bottomLine}></div>
  }

  const showsTitle = plugin.$i18n.transl('core.product.shows', { count: props.pageInfo.totalCount })
  const reviewsTitle = plugin.$i18n.transl('core.product.reviews')
  const sectionTitle = plugin.$i18n.transl('core.product.showsAndReviews')
  if (!hasImages && !hasReviews && !hasFirstReview) {
    return null
  }
  return (
    <>
      <ProductSection showSeparator={false}>
        <div className={cx(styles.wrapper)}>
          {
            (hasImages || hasReviews) && (
              <div className={styles.header}>
                <h2 className={styles.title}>{sectionTitle}</h2>
              </div>
            )
          }
          <div className={cx(styles.images, hasImages && styles.hasImages, showImagesContent && styles.transparent)}>
              <HorizontalColumns
                className={styles.postImageGroup}
                childItems={shows}
                childIs={PostImage}
                onReachEnd={onPostsReachEnd}
                childOptions={{
                  onClick: handleImageClick
                }}
              />
          </div>
          {renderReview()}
        </div>
      </ProductSection>
      {
        showImagesContent &&
        <PostImageContentModal
          onClose={onCloseImagesContent}
          initPreviewIndex={previewImageIndex}
          headerTitle={showsTitle}
          productTitle={productTitle}
          posts={posts}
          headerClass={styles.drawerHeader}
          onPostsReachEnd={props.onPostsReachEnd}
        />
      }
      {
        showReviewsContent &&
        <ReviewsContentModal
          onClose={onCloseReviewsContent}
          headerTitle={reviewsTitle}
          comment={comment}
          product={product}
          onCommentsReachEnd={props.onCommentsReachEnd}
          headerClass={styles.drawerHeader}
          onClickCommentImage={handleClickCommentImage}
          onCommentBySort={props.onCommentBySort}
          isFetchingComments={props.isFetchingComments}
          commentSortData={props.commentSortData}
          showReviewsContent={showReviewsContent}
        />
      }
      {
        showCommentImages &&
        <CommentImagePreviewer
          total={commentImages.length}
          onClose={() => setShowCommentImages(false)}
          index={commentImageIndex}
          items={commentImages}
          onReachEnd={props.onCommentsReachEnd}
          product={props.product}
          $i18n={plugin.$i18n}
          isDesktop={false}
          onAddToCartClick={handleClickAddToCart}
          hideFooter={props.hideCommentImagePreviewerFooter}
        />
      }
    </>
  )
}

export default forwardRef(ShowAndReviewSection)
