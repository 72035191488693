import CollectService from './TrackerPlugin/CollectService'
import ClientOnlyPlugin from '@flamingo_tech/funkgo/src/base/ClientOnlyPlugin'

const DEVICE_KEY_STORAGE = 'ndk'
const APP_DEVICE_KEY_STORAGE = 'adk'

export default class DeviceKeyPlugin extends ClientOnlyPlugin {
  displayName = '$DeviceKey'

  constructor(...args) {
    super(...args)
    const isProductionEnv = process.env.NODE_ENV === 'production'
    this.$http = this.pluginHub.getHttpClient()
    this.$service = new CollectService(this.$http, isProductionEnv)
    this.deviceKey = ''
    this.initDeviceKeyStorage()
    this.initContext()
  }

  /* ------------------------------------- */
  initDeviceKeyStorage() {
    const $storage = this.pluginHub.getStorage()
    const $detector = this.pluginHub.getDetector()

    if ($detector.isApp()) {
      this.deviceKeyStorage = $storage.create(APP_DEVICE_KEY_STORAGE)
    } else {
      this.deviceKeyStorage = $storage.create(DEVICE_KEY_STORAGE)
    }
  }

  /* ------------------------------------- */

  initContext() {
    this.deviceKeyPromise = Promise.resolve(this.getLocalDeviceKey()).then(dk => {
      if (dk) {
        return Promise.resolve(dk)
      } else {
        return this.fetchDeviceInfo().then(deviceInfo => {
          const { deviceKey } = deviceInfo || {}
          if (deviceKey) {
            this.setLocalDeviceKey(deviceKey)
            return Promise.resolve(deviceKey)
          }
          return this.requestDeviceKey(deviceInfo)
        })
      }
    })
    return this.deviceKeyPromise
  }

  /* ------------------------------------- */

  fetchDeviceInfo() {
    const $bridge = this.pluginHub.getPlugin('bridge')

    if ($bridge && $bridge.isAppBridgesEnabled()) {
      // bridge will track error automatically, no need to track here
      return $bridge.getDeviceInfo().catch(err => undefined)
    }

    // for web, no way to get device info, so return an empty object to request a new device key
    return Promise.resolve(undefined)
  }

  // use device info to request a related device key
  // , if the device id is exists at our db, then it will back a exists device key
  // , otherwise it will create a new device key
  requestDeviceKey({ deviceId, mac } = {}) {

    return this.$service.requestDeviceKey({ deviceId, mac }).then(data => {
      this.setLocalDeviceKey(data.deviceKey)
      return Promise.resolve(data.deviceKey)
    })
  }

  /* ------------------------------------- */
  getLocalDeviceKey() {
    if (this.deviceKey) {
      return this.deviceKey
    }
    return this.deviceKeyStorage.getItem()
  }

  setLocalDeviceKey(deviceKey) {
    this.deviceKey = deviceKey

    this.deviceKeyStorage.setItem(deviceKey)
  }

  getDeviceKey = () => {
    return this.deviceKeyPromise.catch(() => {
      const freshedPromise = this.initContext()  // if promise fail at first, try to init once again
      return freshedPromise
    })
  }



  injectProps = {
    $deviceKey: {
      getKey: this.getDeviceKey
    }
  }
}