import CartService from './services/CartService'
import CartModel from './models/CartModel'

import retry from '@flamingo_tech/funkgo/src/utils/retry'
import createListener from '@flamingo_tech/funkgo/src/utils/createListener'
import { SELECTED_STATUS } from '../../utils/cartUtils'

const OPERATION = {
  ADD: 'ADD',
  FIX_DATA: 'FIX_DATA',
  APPLY_DISCOUNT: 'APPLY_DISCOUNT',
  TOUCH: 'TOUCH',
  REMOVE: 'REMOVE',
  UPDATE: 'UPDATE',
  RESET: 'RESET',
}


export default class Cart {
  static create(legacyCart, options) {
    const cart = new Cart(options)
    return cart.create(legacyCart)
  }

  static connect(id, options) {
    const cart = new Cart(options)
    return cart.connect(id)
  }

  static connectWithFull(id, options) {
    const cart = new Cart(options)
    return cart.connectWithFull(id)
  }

  constructor({ $http, pluginHub, couponHub }) {
    this.$http = $http
    this.pluginHub = pluginHub
    this.couponHub = couponHub
    this.cartService = new CartService($http)

    this.initCart()
    this.initListener()
  }

  /* ---------------------------------------------- */

  initCart() {
    this.id = null

    this.model = null
    this._formattedModel = null
  }

  initListener() {
    const { subscribe, unsubscribe, notify } = createListener()

    this.subscribe = subscribe
    this.unsubscribe = unsubscribe
    this.notify = notify
  }

  /* ---------------------------------------------- */

  publishUpdate(mode) {
    this.notify({
      cart: this.getModel(),
      cartOperation: this.generateCartOperationMap(mode),
    })
  }

  /* ---------------------------------------------- */

  trackError(error, label, isFatal = false) {
    const $tracker = this.pluginHub.getPlugin('tracker')

    if ($tracker) {
      $tracker.error({
        category: 'CART',
        isFatal,
        error,
        label,
      })
    }
  }

  trackErrorFatal(error, label) {
    this.trackError(error, label, true)
  }

  /* ---------------------------------------------- */

  callWithRetry(request, mode) {
    const onError = ({ error, currentCount }) => {
      this.trackError(`${error.message} (retry:${currentCount})`, mode)
    }

    const retryMaxCount = 3
    const retryPattern = /(timeout)|(network)|(404)/i

    return retry(request, onError, retryMaxCount, retryPattern).catch(
      (error) => {
        this.trackErrorFatal(error, mode)
        throw error
      }
    )
  }

  /* ---------------------------------------------- */

  create(legacyCart) {
    const request = () =>
      this.cartService.applyAnonymousCart(
        legacyCart,
        this.getCartAssociatedCouponInfo()
      )

    return this.callWithRetry(request, 'create').then((cart) => {
      this.initModel(cart)
      return this
    })
  }

  connect(id) {
    const request = () =>
      this.cartService.getAnonymousCartCount(id, this.getCartAssociatedCouponInfo())

    return this.callWithRetry(request, 'connect').then((cart) => {
      if (!cart) {
        this.initEmptyModel()
      } else {
        this.initModel(new CartModel(cart))
      }
      return this
    })
  }

  connectWithFull(id) {
    const request = () =>
      this.cartService.getAnonymousCart(id, this.getCartAssociatedCouponInfo())

    return this.callWithRetry(request, 'connectWithFull').then((cart) => {
      if (!cart) {
        this.initEmptyModel()
      } else {
        this.initModel(cart)
      }
      return this
    })
  }

  fetchFullCart() {
    const id = this.getId()

    if (!id) {
      return null
    }

    const request = () =>
      this.cartService.getAnonymousCart(id, this.getCartAssociatedCouponInfo())

    return this.callWithRetry(request, 'fetchFull').then((cart) => {
      return this.handleUpdateCart({
        ...this.getModel(),
        ...cart
      }, OPERATION.TOUCH)
    })
  }

  refresh() {
    return this.fetchFullCart()
  }

  /* ---------------------------------------------- */

  initEmptyModel() {
    this.setOriginalModel(null)
  }

  initModel(model) {
    if (!model || !model.id) {
      throw new Error(`[Cart] has wrong parameter`)
    }

    if (this.id && this.id !== model.id) {
      throw new Error(`[Cart] cart id has been changed`)
    }

    this.id = model.id
    this.setOriginalModel(model)
  }

  updateModel(model, mode) {
    if (model.id !== this.id) {
      throw new Error(`[Cart] id is not match`)
    }

    this.setOriginalModel(model)
    this.publishUpdate(mode)
  }

  /* ---------------------------------------------- */

  handleUpdateCart(response, mode) {
    this.updateModel(response, mode)

    return this.getModel()
  }

  updateLineItems(lineItem, mode, eventID) {
    const request = () =>
      this.cartService.updateAnonymousCart(
        this.getId(),
        {
          lineItem,
          ...this.getCartAssociatedCouponInfo(),
        },
        eventID
      )

    // TODO, add a cache to return line items asap, and rollback it if api call failed
    const errorLabel = `updateLineItems.${mode}`
    return this.callWithRetry(request, errorLabel).then((res) => this.handleUpdateCart(res, mode, errorLabel)
    )
  }

  /* ---------------------------------------------- */

  applyCoupon(coupon) {
    const model = this.getModel()

    const couponHub = this.couponHub
    const previousCoupon = this.couponHub.getAvailableCoupon(
      model.appliedCouponId
    )

    if (!couponHub.isCouponAvailable(coupon)) {
      return Promise.reject(new Error('invalid coupon'))
    }

    if (couponHub.isCouponEqual(coupon, previousCoupon)) {
      return Promise.resolve()
    }

    const request = () =>
      this.cartService.updateAnonymousCart(this.getId(), {
        lineItems: model.lineItems,
        appliedCoupon: coupon,
        ...this.getCartAssociatedCouponInfo(),
      })

    return this.callWithRetry(request, 'apply_coupon').then((res) => {
      this.handleUpdateCart(res, OPERATION.APPLY_DISCOUNT, 'apply_coupon')
    })
  }

  /* ---------------------------------------------- */
  getVariantById(id, variants) {
    return variants.filter((variant) => variant.id === id)[0] || null
  }

  getFormattedModel() {
    const model = this.getOriginalModel()
    const currentAddedProductId = this.currentAddedProductId
    this.currentAddedProductId = null

    return {
      ...model,
      appliedCoupon: this.couponHub.getAvailableCoupon(model.appliedCouponId),
      currentAddedProductId: currentAddedProductId
    }
  }

  /* ---------------------------------------------- */
  generateCartOperationMap(cartOperation) {
    return {
      add: cartOperation === OPERATION.ADD,
      remove: cartOperation === OPERATION.REMOVE,
      replace:
        cartOperation === OPERATION.REPLACE ||
        cartOperation === OPERATION.RESET,
      applyDiscount: cartOperation === OPERATION.APPLY_DISCOUNT,
      touch: cartOperation === OPERATION.TOUCH,
    }
  }

  getCartAssociatedCouponInfo() {
    return {
      couponCenterId: this.couponHub.getCouponCenter().id
    }
  }

  getId() {
    return this.id
  }

  getOriginalModel() {
    return this.model
  }

  setOriginalModel(value) {
    this.model = value
    this._formattedModel = null
  }

  setOriginalModelProp(key, value) {
    if (this.model) {
      this.model[key] = value
    }
  }

  getModel() {
    if (!this._formattedModel) {
      this._formattedModel = this.getFormattedModel()
    }
    return this._formattedModel
  }

  /* ---------------------------------------------- */
  fetchModel() {
    // keep async, and it can be change to fetch from shopify server on the future
    return Promise.resolve(this.getModel())
  }

  createDraftOrder = (options = {}) => {
    // TODO: Whether or not the Checkout is ready and can be completed.
    //   Checkouts may have asynchronous operations that can take time to finish.
    //   If you want to complete a checkout or ensure all the fields are populated and up to date,
    //   polling is required until the value is true
    //   NEED TO CHECK model.ready

    if (options.flamingoCheckout) {
      return this.createOrderViaFlamingoAPI(options)
    }

    return this.createOrderViaShopifyAPI()
  }

  createAnonymousDraftOrder = (anonymousUserToken, options) => {
    const saleableLineItems = this.getModel().lineItems.filter((lineItem) => {
      if (options.isSubmitAll) {
        return lineItem.saleable
      }
      return lineItem.saleable && lineItem.selectedFlag
    })
    const { couponCenterId } = this.getCartAssociatedCouponInfo()

    return this.cartService
      .createAnonymousDraftOrder(
        saleableLineItems,
        anonymousUserToken,
        couponCenterId,
        (options && options.shippingAddress) || null
      )
      .then((data) => ({
        type: 'flamingo',
        shopifyOrderId: this.getId(),
        orderId: data.orderId,
        totalFee: data.totalFee,
      }))
  }

  createHalfDraftOrder = ({ isSubmitAll }) => {
    const saleableLineItems = this.getModel().lineItems.filter((lineItem) => {
      if (isSubmitAll) {
        return lineItem.saleable
      }
      return lineItem.saleable && lineItem.selectedFlag
    })
    const { couponCenterId } = this.getCartAssociatedCouponInfo()

    return this.cartService
      .createHalfDraftOrder(
        saleableLineItems,
        couponCenterId
      )
      .then((data) => ({
        type: 'flamingo',
        shopifyOrderId: this.getId(),
        orderId: data.orderId,
        totalFee: data.totalFee,
      }))
  }

  createOrderViaFlamingoAPI({ isSubmitAll, shippingAddress, bizType }) {
    // need login to call above api
    const { lineItems } = this.getModel()
    const saleableLineItems = lineItems.filter((lineItem) => {
      if (isSubmitAll) {
        return lineItem.saleable
      }
      return lineItem.saleable && lineItem.selectedFlag
    })

    return this.cartService
      .createDraftOrder(saleableLineItems, { shippingAddress, bizType })
      .then((data) => ({
        type: 'flamingo',
        shopifyOrderId: this.getId(),
        orderId: data.orderId,
        totalFee: data.totalFee,
      }))
  }

  createOrderViaShopifyAPI() {
    const model = this.getOriginalModel()
    const targetUrl = model.webUrl

    const $router = this.pluginHub.getPlugin('router')
    // since target url is not at same host, add tracking info for ga
    const webUrlFetching = $router
      ? $router.makeTrackingUrl(targetUrl).catch(() => targetUrl)
      : Promise.resolve(targetUrl)

    return webUrlFetching.then((webUrl) => ({
      type: 'shopify',
      shopifyOrderId: this.getId(),
      webUrl,
    }))
  }

  /* ---------------------------------------------- */
  getTotalQuantityFromLineItems = (lineItems) => {
    const reducer = (accumulator, currentValue) => {
      return accumulator + currentValue.quantity
    }
    return lineItems.reduce(reducer, 0)
  }

  addVariant = ({ variant, quantity, product, trackChannel, needFetchFullAfterAddCart }) => {
    // as for event or search product feeds, initial product main variation has no sku id
    // direct return until product data is fetched
    if (!variant.id) {
      return Promise.resolve()
    }

    this.currentAddedProductId = product ? product.id : null

    const { lineItems } = this.getModel()
    const trackItems = lineItems.map(itemObj => Object.assign({}, itemObj))

    const currentVariant = this.getVariantById(variant.id, trackItems)

    const eventID = this.getId()

    if (currentVariant) {
      currentVariant.quantity += quantity
    } else {
      trackItems.unshift({
        id: variant.id,
        quantity,
      })
    }

    const $user = this.pluginHub.getPlugin('user')

    if (this.pluginHub.getPlugin('tracker') && product) {
      const $tracker = this.pluginHub.getPlugin('tracker')
      $tracker.addToCart(
        variant,
        product,
        quantity,
        {
          channel: trackChannel,
          lineItems: trackItems,
          cartId: this.getId(),
        },
        eventID
      )

    }

    const $detector = this.pluginHub.getDetector()

    const request = () => this.cartService[($detector.isDesktop() || needFetchFullAfterAddCart)? 'addAnonymousCart' : 'addAnonymousCartWithoutCalc'](
      this.getId(),
      {
        lineItem: {
          ...variant,
          quantity
        },
        ...this.getCartAssociatedCouponInfo(),
      },
      eventID,
      {
        email: $user.getAnonymousLoginEmail()
      }
    )

    const errorLabel = `updateLineItems.${OPERATION.ADD}`

    return this.callWithRetry(request, errorLabel).then(res => {
      if ($detector.isApp()) {
        const $bridge = this.pluginHub.getPlugin('bridge')
        if ($bridge) {
          $bridge.refreshCart()
        }
      }
      this.handleUpdateCart({
        ...this.getModel(),
        ...res
      }, OPERATION.ADD)
    })

  }

  replaceVariant = ({ variant, itemId }) => {
    if (!variant.id) {
      return Promise.resolve()
    }

    const eventID = this.getId()

    const request = () => this.cartService.replaceAnonymousCart(
      this.getId(),
      {
        lineItem: {
          ...variant,
          itemId
        },
        ...this.getCartAssociatedCouponInfo(),
      },
      eventID
    )

    const errorLabel = `updateLineItems.${OPERATION.UPDATE}`

    return this.callWithRetry(request, errorLabel).then(res => {
      this.handleUpdateCart({
        ...this.getModel(),
        ...res
      }, OPERATION.UPDATE)
    })
  }

  // 由于接口设计问题，这里主要是为了清除已过期商品，所以没有返回更新后的购物车结果
  removeVariants = lineItems => {
    const request = () =>
      this.cartService.deleteAnonymousCart(
        this.getId(),
        {
          lineItems
        }
      )

    const errorLabel = `updateLineItems.${OPERATION.REMOVE}`
    return this.callWithRetry(request, errorLabel).then(() =>
      this.refresh()
    )
  }

  updateVariant = lineItem => {
    return this.updateLineItems(lineItem, OPERATION.UPDATE)
  }

  selectCart = (lineItem, isSelectAll) => {
    let selectedStatus = isSelectAll ? SELECTED_STATUS.ALL :  SELECTED_STATUS.NONE
    let item

    if (lineItem) {
      selectedStatus = SELECTED_STATUS.PARTIAL
      item = {
        ...lineItem,
        selectedFlag: !lineItem.selectedFlag
      }
    }

    return this.cartService.updateSelectCart({
      shoppingCartId: this.getId(),
      couponCenterId: this.couponHub.getCouponCenter().id,
      item,
      selectedStatus
    })
      .then(res => this.handleUpdateCart(res, OPERATION.UPDATE))
  }

  touchCart() {
    return Promise.resolve(this.publishUpdate(OPERATION.TOUCH))
  }
}