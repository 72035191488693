import React, { useContext, useState } from 'react'
import { PluginContext } from '@flamingo_tech/funkgo'

import EmailHideCoupon from '../../../components/Store/Coupon/EmailHideCoupon'

import WheelOverlay from '../../../components/Store/Coupon/WheelOverlay'

/* ----------------------------------------------- */
const WheelOverlayContainer = props => {
  const plugin = useContext(PluginContext)
  const [hide, setHide] = useState(false)
  const isDesktop = plugin.$detector.isDesktop()

  const couponHub = plugin.$store && plugin.$store.couponHub

  const isWheelAvailable = props.show
  const [hideBar, setHideBar] = useState(!props.isBuoy) // 用户邮箱检测

  const wheelCoupons = isDesktop ? [props.coupon] : props.coupons

  const [email, setEmail] = useState('')
  const [submitting, setSubmitting] = useState(false)

  const hideEmailCollect = props.isForAds

  const handleCancel = () => {
    plugin.$track.event({
      category: 'Wheel',
      action: 'cancel_wheel',
      label: wheelCoupons.map(item => item.id).join(','),
      nonInteraction: false
    })

    plugin.$store.emailCoupon.setEmailCouponMode(true)
    setHide(true)
    setHideBar(false) // 展示右侧 Bar

    if (props.onClose) {
      props.onClose()
    }
  }

  const handleTake = (email) => {
    plugin.$track.event({
      category: 'Wheel',
      action: 'take_wheel',
      label: wheelCoupons.map(item => item.id).join(','),
      nonInteraction: false
    })

    if (email) {
      plugin.$track.trackEmail(email, 'wheelPromotion')
      plugin.$user.saveAnonymousLoginEmail(email)
    }

    return couponHub.batchTake(wheelCoupons).then(() => {
      plugin.$toastSuccess(plugin.$i18n.transl('core.promotion.wheel.luckyDay'))
      // record as postponed, so that it will auto refresh after cache expired
      setHide(true)
      if (props.onConfirm) {
        props.onConfirm()
      }
    }).catch(() => {
      plugin.$toast(plugin.$i18n.transl('error.continue'))
    })
  }

  const handleSubmit = ev => {
    ev.preventDefault()
    if (!submitting) {
      if ((email && email.lastIndexOf('@') > -1) || hideEmailCollect) {
        setSubmitting(true)
        return handleTake(email).then(() => {
          setHideBar(true)
          setSubmitting(false)
        }).catch(() => {
          setSubmitting(false)
        })
      } else {
        plugin.$toast(plugin.$i18n.transl('core.promotion.checkEmail'))
      }
    } else {
      return Promise.resolve()
    }
  }

  const componentProps = {
    wheelOverlayProps: {
      email,
      onChange: ev => setEmail(ev.target.value),
      handleSubmit,
      disabled: submitting,
      coupon: props.coupon,
      coupons: props.coupons,
      hideEmailCollect
    },
    coupon: props.coupon,
  }

  if (!hideBar) {
    if (isWheelAvailable) {
      return null
    } else {
      return <EmailHideCoupon {...componentProps} />
    }
  }

  if (isWheelAvailable) { // 第一次进入大转盘弹窗
    return <WheelOverlay {...componentProps} hide={hide} onClose={handleCancel} />
  }

  if (!hideBar && isDesktop) {
    return <EmailHideCoupon {...componentProps} />
  }


  return null
}

export default WheelOverlayContainer
