import React from 'react'
import ClientOnlyPlugin from '@flamingo_tech/funkgo/src/base/ClientOnlyPlugin'

import Cart from './StorePlugin/Cart'
import CouponHub from './StorePlugin/CouponHub'
import WishList from './StorePlugin/Wish'
import EmailCoupon from './StorePlugin/EmailCoupon'
import UserService from '../services/UserService'

import Parabola from '../hooks/Parabola'
import AtcBall from '../components/Store/Product/AtcBall'

import createListener from '@flamingo_tech/funkgo/src/utils/createListener'
import { debouncePromise } from '@flamingo_tech/funkgo-utils/promiseUtils'

/* --------------------------------- */

const silentFail = () => undefined

const SITE_DEFAULT_CART_ID_STORAGE = 'sdc'
const DEVICE_KEY_STORAGE = 'ndk'

export default class StorePlugin extends ClientOnlyPlugin {
  displayName = '$Store'
  couponHub = new CouponHub({ pluginHub: this.pluginHub })
  emailCoupon = new EmailCoupon({ pluginHub: this.pluginHub })
  $http = this.pluginHub.getHttpClient()
  $site = this.pluginHub.getSite()
  $detector = this.pluginHub.getDetector()
  $user = this.pluginHub.getPlugin('user')
  $bridge = this.pluginHub.getPlugin('bridge')
  wishList = new WishList({ $http: this.$http, $user: this.$user })
  userService = new UserService(this.$http)


  siteDefaultCartStorage = null
  defaultCartClient = null
  defaultWishClient = null
  initCartPromise = Promise.resolve()

  start() {
    const $storage = this.pluginHub.getStorage()

    this.couponHub.start()

    this.siteDefaultCartStorage = $storage.create(SITE_DEFAULT_CART_ID_STORAGE)
    this.deviceKeyStorage = $storage.create(DEVICE_KEY_STORAGE)
    this.initListener()
  }

  initWishListClient = () => {
    return this.wishList.connect()
      .then(defaultWishClient => {
        defaultWishClient.subscribe(this.notifyWishUpdate)
        this.defaultWishClient = defaultWishClient
        return defaultWishClient
      })
  }

  connectWishList = () => {
    const process = this.defaultWishClient
      ? Promise.resolve(this.defaultWishClient)
      : this.initWishListClient()

    return process.then(wishClient => this.fetchWishModel(wishClient))
  }

  /* ---------------------------------------------- */
  makeCartOptions() {
    return {
      pluginHub: this.pluginHub,
      couponHub: this.couponHub,
      $http: this.$http
    }
  }

  /* ---------------------------------------------- */
  initListener = () => {
    this.listener = createListener()
    this.couponHub.subscribe(this.notifyCouponHubUpdate)
  }

  notifyCartUpdate = ({ cart, cartOperation }) => {
    this.listener.notify({ cart, cartOperation })
  }

  notifyWishUpdate = ({ wishTotal }) => {
    this.listener.notify({ wishTotal })
  }

  notifyCouponHubUpdate = () => {
    return this.listener.notify({
      availableCoupons: this.couponHub.getAvailableCoupons(),
    })

  }

  /* ---------------------------------------------- */

  // it may create new cart at this process
  handleDefaultCartInitial(defaultCartClient) {
    // if the default cart has been checked
    if (defaultCartClient.isCartChecked()) {
      // create a new cart
      return this.createNewCart()
    } else if (defaultCartClient.isCartUnavailable()) {
      // sometimes the cart id may query an empty cart, then create a new one
      return this.createNewCart()
    }
    return defaultCartClient
  }

  handleDefaultCartConnected(defaultCartClient) {
    this.initDefaultCartClient(defaultCartClient)

    return defaultCartClient
  }

  /* ----------------------------------------------------------------- */

  initDefaultCartClient(defaultCartClient) {
    if (this.defaultCartClient) {
      this.destroyDefaultCartClient()
    }

    // if the cart has been changed, e.g. cart has been checked, then save new id
    const cartId = defaultCartClient.getId()
    this.setCurrentCardId(cartId)

    // cache cart client in runtime
    this.defaultCartClient = defaultCartClient
    this.defaultCartClient.subscribe(this.notifyCartUpdate)
  }

  destroyDefaultCartClient() {
    if (this.defaultCartClient) {
      this.defaultCartClient.unsubscribe(this.notifyCartUpdate)
    }
    this.clearCurrentCardId()

    this.defaultCartClient = null
  }

  /* ----------------------------------------------------------------- */

  connectSpecificCart = (id) => {
    return Cart.connect(id, this.makeCartOptions())
  }

  createNewCart(props) {
    return Cart.create(props, this.makeCartOptions())
  }

  /* ----------------------------------------------------------------- */
  connectDefaultCart() {
    const defaultCartId = this.getCurrentCartId()

    let connectProcess

    if (defaultCartId) {
      connectProcess = this.connectSpecificCart(defaultCartId).catch(err => {
        throw err
      })
    } else {
      connectProcess = this.createNewCart()
    }

    return connectProcess
      .then(cartClient => this.handleDefaultCartConnected(cartClient))
  }

  initDefaultCart() {
    const defaultCartId = this.getCurrentCartId()

    if (defaultCartId) {
      return this.connectSpecificCart(defaultCartId).catch(err => {
        throw err
      }).then(cartClient => this.handleDefaultCartConnected(cartClient))
    } else {
      return Promise.resolve({
        fetchModel: () => {
          return Promise.resolve({
            totalQuantity: 0,
            lineItems: []
          })
        }
      })
    }
  }

  /* ----------------------------------------------------------------- */
  fetchCartModel(cartClient) {
    return cartClient.fetchModel().then(cart => ({
      cart,
      availableCoupons: this.couponHub.getAvailableCoupons()
    }))
  }

  fetchWishModel(wishClient) {
    return wishClient.getModel()
  }

  connectCart = debouncePromise(() => {
    this.initCartPromise = this.defaultCartClient
      ? Promise.resolve(this.defaultCartClient)
      : this.connectDefaultCart()

    return this.initCartPromise.then(cartClient => this.fetchCartModel(cartClient)).catch(() => {
      this.initCartPromise = Promise.resolve()
    })
  })

  initCart = debouncePromise(() => {
    this.initCartPromise = this.defaultCartClient
      ? Promise.resolve(this.defaultCartClient)
      : this.initDefaultCart()

    return this.initCartPromise.then(cartClient => this.fetchCartModel(cartClient)).catch(() => {
      this.initCartPromise = Promise.resolve()
    })
  })

  /* ----------------------------------------------------------------- */

  subscribeCart = (fn, options) => {
    this.listener.subscribe(fn, options)
  }

  unsubscribeCart = (fn, options) => {
    this.listener.unsubscribe(fn, options)
  }

  subscribeWish = (fn, options) => {
    this.listener.subscribe(fn, options)
  }

  unsubscribeWish = (fn, options) => {
    this.listener.unsubscribe(fn, options)
  }

  toggleWishItem = params => {
    const process = this.defaultWishClient
      ? Promise.resolve(this.defaultWishClient)
      : this.initWishListClient()

    return process.then(wishClient => wishClient.toggleItem(params))
  }

  /* ----------------------------------------------------------------- */

  doExecCart = (method, args = []) => {
    if (!this.defaultCartClient) {
      return Promise.reject('cart init failed')
    }

    if (typeof this.defaultCartClient[method] !== 'function') {
      return Promise.reject(`${method} is not a valid method`)
    }
    return this.defaultCartClient[method](...args)
  }

  execCart = (method, args) => {
    return this.initCartPromise.then(() => { // always wait for init promise
      if (!this.defaultCartClient) { // there is no cart after initialization
        if (method === 'addVariant') { // apply for new cart then add cart
          return this.connectCart().then(() => this.doExecCart(method, args))
        } else {
          return Promise.resolve
        }
      } else { // cart is ready, just do exec cart
        return this.doExecCart(method, args)
      }
    })
  }

  getCurrentCartId = () => {
    const site = this.$site.getSiteInfo({
      pluginHub: this.pluginHub,
      force: true
    })

    return this.siteDefaultCartStorage.getItem({})[site.id]
  }

  setCurrentCardId = id => {
    const site = this.$site.getSiteInfo({
      pluginHub: this.pluginHub
    })

    const currentSiteDefaultCardItem = this.siteDefaultCartStorage.getItem({})

    this.siteDefaultCartStorage.setItem(Object.assign({}, currentSiteDefaultCardItem, {
      [site.id]: id
    }))
  }

  clearCurrentCardId = () => {
    this.setCurrentCardId(null)
  }

  replaceDefaultCart = cartId => {
    if (!cartId) {
      return Promise.reject(new Error('[StorePlugin] must specific cart id for set default cart'))
    }

    if (cartId === this.getCurrentCartId()) {
      return Promise.resolve(false)
    }

    if (this.defaultCartClient) {
      this.destroyDefaultCartClient()
    }

    this.setCurrentCardId(cartId)
    return this.connectCart().then(() => true)
  }

  /* ----------------------------------------------------------------- */
  refresh = (force) => {
    if (force) {
      this.defaultCartClient = null
      this.execCart('refresh').catch(silentFail)
    } else {
      if (this.defaultCartClient) {
        this.execCart('refresh').catch(silentFail)
      }
    }

    this.couponHub.refresh()
  }

  ensureCart = () => {
    if (this.defaultCartClient) {
      return this.fetchCartModel(this.defaultCartClient).then(res => res.cart)
    } else {
      return this.createNewCart()
    }
  }

  handleStartAtcParabola = ({ startRef, onDone = () => null }) => {
    const targetDom = document.getElementById('atc_parabola_destination_main') || document.getElementById('atc_parabola_destination_with_shake')
    const withShake = targetDom === document.getElementById('atc_parabola_destination_with_shake')

    if (startRef && startRef.current && targetDom) {
      const parabola = new Parabola({
        origin: startRef.current,
        target: targetDom,
        withShake,
        element: <AtcBall />,
        time: 500,
        callback: onDone
      })

      parabola.move()

      return true
    } else {
      return false
    }
  }

  syncAssets = ({ syncCartFlag = true } = {}) => {
    if (this.$detector.isApp()) {
      return Promise.resolve()
    }
    const defaultCartId = this.getCurrentCartId()
    const ensureCartCreated = defaultCartId ? Promise.resolve({ id: defaultCartId }) : this.connectDefaultCart()
    return Promise.all([ensureCartCreated, this.couponHub.ensureCouponCenterCreated()])
    .then(([cart]) => {
      const couponCenterId = this.couponHub.getCouponCenter().id
      const shoppingCartId = cart.id

      return this.userService.syncAssets({
        couponCenterId,
        shoppingCartId,
        syncCartFlag
      })
        .then(() => {
          this.refresh()
        })
    })
  }

  getUserId() {
    if (this.$user && this.$user.getToken()) {
      const { id } = this.$user.getToken()
      return id
    }
    return undefined
  }

  syncUserDeviceKey = () => {
    if (this.$detector.isApp()) {
      return Promise.resolve()
    }

    const userId = this.getUserId()
    const deviceKey = this.deviceKeyStorage.getItem()

    const params = {}

    if (deviceKey) {
      params.deviceKey = deviceKey
    }

    if (userId) {
      params.userId = userId
    }

    if (deviceKey) {
      return this.userService.syncUserDeviceKey(params)
    }

    return Promise.resolve()
  }

  /* ----------------------------------------------------------------- */
  injectProps = {
    $store: {

      initCart: this.initCart,
      syncAssets: this.syncAssets,
      syncUserDeviceKey: this.syncUserDeviceKey,

      subscribeCart: this.subscribeCart,
      unsubscribeCart: this.unsubscribeCart,

      subscribeWish: this.subscribeWish,
      unsubscribeWish: this.unsubscribeWish,
      toggleWishItem: this.toggleWishItem,

      execCart: this.execCart,

      getCurrentCartId: this.getCurrentCartId,
      replaceDefaultCart: this.replaceDefaultCart,

      couponHub: this.couponHub,
      pluginHub: this.pluginHub,
      refresh: this.refresh,

      emailCoupon: this.emailCoupon,

      ensureCart: this.ensureCart,

      connectWishList: this.connectWishList,

      startAtcParabola: this.handleStartAtcParabola,
    }
  }
}
