import BaseService from '@flamingo_tech/funkgo/src/base/BaseService'

import {
  retryIfTimeout
} from '../../utils/requestUtils'


const DEVICE_KEY_ENDPOINT = '/app-push/api/device-key'
const ANALYTICS_BASE_URI = '/data/process/event/track'

const TIME_OUT = 10000

const getTrackServiceEndpoint = (isProductionEnv, location = window.location) => {
  let endpointOrigin = ''

  if (isProductionEnv) {
    const { origin } = location

    if (origin === 'https://test.flamingo.shop') {
      endpointOrigin = 'https://test.flamingo.shop'
    } else {
      endpointOrigin = 'https://callback.flamingo.shop'
    }
  }

  return endpointOrigin
}

export default class CollectService extends BaseService {
  constructor($http, isProductionEnv) {
    super($http)
    this.serviceEndpoint = getTrackServiceEndpoint(isProductionEnv)
    this.setTimeout(TIME_OUT)
  }

  requestDeviceKey({ deviceId, mac }) {
    return retryIfTimeout(
      () => this.post(`${this.serviceEndpoint}${DEVICE_KEY_ENDPOINT}`, {
        deviceId,
        mac,
      })
    )
  }

  updateDeviceInfo(deviceKey, payload) {
    return this.put(`${this.serviceEndpoint}${DEVICE_KEY_ENDPOINT}`, {
      ...payload,
      deviceKey,
    })
  }

  collect(payload, context) {
    const dataArray = Array.isArray(payload)
    ? payload
    : [payload]

    // collect has a separate domain
    return this.post(`${this.serviceEndpoint}${ANALYTICS_BASE_URI}`, {
      context,
      data: dataArray,
    })
  }

  notifyFBTrack(action, params, eventID) {
    return this.post(`${this.serviceEndpoint}/data/process/facebook/track`, {
      eventName: action,
      eventData: params
    },
    {
      headers: {
        'X-fb-event-id': eventID || ''
      }
    })
  }
}