import React, { useState, useEffect } from 'react'

import { withDrawer } from '../../common/Drawer/Drawer'
import Slider from '../../common/Slider/Slider'
import Image from '../../common/Image/Image'
import StarRating from './StarRating'

import { formatTime } from '../../../utils/dateUtils'
import { getDiscountText } from '../../../utils/priceUtils'

import styles from './CommentImagePreviewer.module.css'
import { withPlugin } from '@flamingo_tech/funkgo'

import cx from '../../../utils/className'

const REACH_END_DISTANCE = 3 // trigger reachEnd once there are ${REACH_END_DISTANCE} shows left


const Previewer = props => {
  const [curIndex, setCurIndex] = useState(-1)
  const isDesktop = props.$detector.isDesktop()
  const isApp = props.$detector.isApp()
  const sliderRef = React.createRef()


  useEffect(() => {
    const slider = sliderRef.current

    if (curIndex < 0) {
      setCurIndex(props.index)
      if (slider) {
        slider.slickGoTo(props.index)
      }
    }
  }, [sliderRef, curIndex, props.index])

  const handleSwipeChange = index => {
    setCurIndex(index)
    // notify to fetch more data
    const reachEndDistance = props.distance || REACH_END_DISTANCE

    if (index >= props.items.length - 1 - reachEndDistance) {
      props.onReachEnd()
    }
  }

  return (
    <div className={cx(styles.wrapper, isDesktop && styles.inDesktop, isApp && styles.isApp)} ref={props.scrollAreaRef}>
      <div className={styles.header}>
        <span>{curIndex + 1}/{props.total}</span>
        <div className={styles.closeBtn} onClick={props.onClose} />
      </div>
      <Slider ref={sliderRef} afterChange={handleSwipeChange}>
        {
          props.items.map(item => (
            <CommentImagePreviewContent item={item} {...props} key={item.key || item.id} />
          ))
        }
      </Slider>
      <div className={styles.footerWrapper}>
        <CommentTextMask {...props.items[curIndex]} $i18n={props.$i18n}/>
        {
          !props.hideFooter && <CommentImagePreviewFooter {...props}/>
        }
      </div>
    </div>
  )
}

const withDrawerPreviewer = withDrawer(Previewer, { fullScreen: true })

const CommentImagePreviewer = props => {

  const total = props.total || 0

  if (total <= 0) {
    return null
  }

  const PreviewerModal = props.PreviewerContent  ? props.PreviewerContent : withDrawerPreviewer

  return (
    <PreviewerModal
      {...props}
    />
  )
}

const CommentImagePreviewContent = props => {
  const { item } = props

  return (
    <div className={cx(styles.imageWrapper, props.isDesktop && styles.inDesktop)} key={props.key}>
       <Image src={item.src}
        className={styles.image}
        middle
        objectFit='contain'
      />
    </div>
  )
}

const CommentTextMask = props => {
  if (!props.content) {
    return null
  }

  const overallFitStr = props.overallFit ? `${props.overallFit.name}: ${props.overallFit.value}` : ''

  return (
    <div className={styles.content}>
      <div className={styles.name}>{props.user.name} {props.$i18n.transl('common.on')} {formatTime(props.publishTime)}</div>
      <div className={styles.star}>
        <StarRating star={props.star}/>
      </div>
      {
        props.sku &&
        <div className={styles.sku}>{props.sku} {overallFitStr}</div>
      }
      <div className={styles.text}>{props.content}</div>
    </div>
  )
}

const CommentImagePreviewFooter = props => {
  const { product } = props
  const { msrp, price, displayPrice } = product.mainVariation
  const hasOriginalPrice = Boolean(msrp && msrp > price)

  return (
    <div className={styles.footer}>
      <div className={cx(styles.price, hasOriginalPrice && styles.hasDiscount)}>
        {
          product.isFlashSale &&
          <span className={styles.flashSaleIcon}>&#xe659;</span>
        }
        <span>{displayPrice}</span>
        {
          hasOriginalPrice && <span className={styles.discount}>{getDiscountText(price, msrp)}</span>
        }
      </div>
      {
        product.availableForSale && !props.isDesktop &&
        <div
          className={styles.btn}
          onClick={props.onAddToCartClick}
        >{product.availableForSale ? props.$i18n.transl('core.product.addToCart') : props.$i18n.transl('core.product.outOfStock')}</div>
      }
    </div>
  )
}

export default withPlugin(CommentImagePreviewer)
