import { BaseService } from '@flamingo_tech/funkgo'

import { ORDER_TYPE, PAYMENT_METHOD, DEFAULT_PAYMENT_METHODS } from '../utils/Checkout/checkoutUtils'
import { prepareOrderTrackingDetails } from '../utils/logisticsUtils'
import {
  retryIfTimeout,
} from '../utils/requestUtils'

// for display order detail
import OrderDetailModel from '../models/Order/OrderDetailModel'

// for checkout
import DraftOrderModel, { CalcAmountModel } from '../models/Order/DraftOrderModel'


const DRAFT_ORDER_PAY_STATUS = {
  PENDING: 'pending',
  PAID: 'paid',
}

const TIME_OUT = 10000
const PAYMENT_TIME_OUT = 20000

const withAnonymousUserTokenHeader = (anonymousUserToken, args) => ({
  ...args,
  headers: {
    'X-access-token': anonymousUserToken
  }
})

const withAnonymousUserTokenAndEventIDHeader = (anonymousUserToken, eventID, args) => ({
  ...args,
  headers: {
    'X-access-token': anonymousUserToken,
    'X-fb-event-id': eventID || ''
  }
})

class OrderService extends BaseService {
  static getFallbackOrderLogisticTracking() {
    return {
      orderId: '',
      orderAddress: '',
      email: '',
      splitFlag: false,
      orderTracks: []
    }
  }

  getOrderLogisticTracking({ shopifyOrderId, orderId }) {
    const requestUrl = shopifyOrderId ? `/order/api/v1/logistic/shopify/orders/${shopifyOrderId}/tracks` : `/order/api/v1/logistic/orders/${orderId}/tracks`

    return retryIfTimeout(() =>
      this.get(requestUrl, {
        timeout: TIME_OUT
      }).then(data => ({
        ...data,
        orderTracks: (data.orderTracks || []).map(orderTrack => ({
          ...orderTrack,
          trackDetails: prepareOrderTrackingDetails(orderTrack)
        }))
      }))
    )
  }

  getOrderLogisticTrackingNoAuth({ orderId, code, sign }) {
    const requestUrl = `order/api/v1/logistic/noLogin/orders/${orderId}/tracks`

    return retryIfTimeout(() =>
      this.get(requestUrl, {
        timeout: TIME_OUT,
        params: { code, sign },
      }).then(data => ({
        ...data,
        orderTracks: (data.orderTracks || []).map(orderTrack => ({
          ...orderTrack,
          trackDetails: prepareOrderTrackingDetails(orderTrack)
        }))
      }))
    )
  }

  getOrderInfo({ shopifyOrderId, orderId, checkoutSid }) {
    const params = {
      sid: checkoutSid
    }

    if (shopifyOrderId) {
      params.shopifyOrderId = shopifyOrderId
    }

    if (orderId) {
      params.orderId = orderId
    }

    return retryIfTimeout(() =>
      this.get('/order/api/v1/orders/info', {
        params,
        timeout: TIME_OUT
      }).then(res => {
        if (!res) {
          throw new Error(`no data, shopifyOrderId=${shopifyOrderId}`)
        }
        return new OrderDetailModel(res)
      })
    )
  }

  getOrderInfoNoAuth({ shopifyOrderId, orderId, checkoutSid }) {
    const params = {
      sid: checkoutSid
    }

    if (shopifyOrderId) {
      params.shopifyOrderId = shopifyOrderId
    }

    if (orderId) {
      params.orderId = orderId
    }

    return retryIfTimeout(() =>
      this.get('/order/api/v1/orders/info/noAuthor', {
        params,
        timeout: TIME_OUT
      }).then(res => {
        if (!res) {
          throw new Error(`no data, shopifyOrderId=${shopifyOrderId}`)
        }
        return new OrderDetailModel(res)
      })
    )
  }

  checkShopifyEmail({ email, shopifyOrderNo, shopifyOrderId }) {
    return this.post('/client/api/v2/order/check', {
      email,
      shopifyOrderNo,
      shopifyOrderId
    }, {
      timeout: TIME_OUT
    })
  }

  getOrders({
    statuses,
    cursor,
    limit,
  } = {}) {
    return this.get('/client/api/v2/2019-09-26/orders', {
      timeout: TIME_OUT,
      params: {
        statuses,
        cursor,
        limit
      },
    })
  }

  archiveOrder(orderId) {
    return this.post(`/client/api/v2/order/${orderId}/archive`, {}, {
      timeout: TIME_OUT
    })
  }

  getOrderCount() {
    return this.get(`/client/api/v2/orders/count`)
  }

  /** payments */
  getDraftOrderInfo({draftOrderId, orderType, eventID, couponCenterId} = {}, anonymousUserToken) {
    const req = this._doDraftOrderInfoReq({draftOrderId, orderType, eventID, couponCenterId}, anonymousUserToken)

    return req.then(res => {
      if (!res || res.payStatus === DRAFT_ORDER_PAY_STATUS.PAID) {
        let e = new Error('this order has been already paid!')
        e.errorCode = 'paid'
        e.customParams = { bizType: res.bizType }
        throw e
      }

      return new DraftOrderModel(res)
    })
  }

  _doDraftOrderInfoReq({draftOrderId, orderType, eventID, couponCenterId } = {}, anonymousUserToken) {
    const url = `/client/api/v4/order/draft/${draftOrderId}${orderType === ORDER_TYPE.ORDER ? '?from=order' : ''}`
    const args = {
      timeout: TIME_OUT,
      params: {
        couponCenterId
      }
    }
    const withEventHeaderArgs = {
      ...args,
      headers: {
        'X-fb-event-id': eventID || ''
      }
    }

    return retryIfTimeout(
      () => this.get(
        url,
        anonymousUserToken ? withAnonymousUserTokenAndEventIDHeader(anonymousUserToken, eventID, args) : withEventHeaderArgs
      )
    )
  }

  // create draft order
  createDraftOrder(itemInfoList, bizType = 'normal') {

    return retryIfTimeout(
      () => this.post('/client/api/v3/order/draft', {
        bizType,
        buyMethod: 'direct',
        itemInfoList
      }).then(data => ({
        orderId: data.orderId,
      }))
    )
  }

  createExpressOrder({ orderInfo, cartId, couponCenterId, eventID }) {
    const args = { timeout: TIME_OUT }
    const withEventHeaderArgs = {
      ...args,
      headers: {
        'X-fb-event-id': eventID || ''
      }
    }
    const { totalPrice, shippingFee, lineItemsSubtotalPrice, discountFee, itemQuantity, itemInfoList, appliedCouponId, shippingAddress } = orderInfo

    return retryIfTimeout(() =>
      this.post('/client/api/v2/order/draft/createExpressOrder', {
        buyMethod: 'cart',
        bizType: 'normal',
        shoppingCartId: cartId,
        couponCenterId,
        couponId: appliedCouponId,
        totalFee: totalPrice,
        freight: shippingFee,
        productFee: lineItemsSubtotalPrice,
        discountFee,
        totalQuantity: itemQuantity,
        itemInfoList: itemInfoList.map(item => ({
          skuId: item.id,
          price: item.variantPrice,
          quantity: item.quantity,
          itemId: item.itemId
        })),
        shippingAddress,
        email: shippingAddress.email
      }, withEventHeaderArgs)
    )
  }


  submitDraftOrder({ couponId, couponCenterId, draftOrderId, shippingAddress, orderType } = {}, anonymousUserToken) {
    if (orderType === ORDER_TYPE.GROUP_BUY) {
      return this._doSubmitGroupBuyOrder(draftOrderId, shippingAddress)
    } else {
      const args = { timeout: TIME_OUT }

      return retryIfTimeout(
        () => this.post('/client/api/v3/order/draft/submit', {
          couponId,
          couponCenterId,
          orderId: draftOrderId,
          shippingAddress
        }, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args)
      )
    }
  }


  submitWithCreatePayOrder({ couponId, couponCenterId, draftOrderId, shippingAddress, payMethod = 'paypal', checkoutToken } = {}, anonymousUserToken) {
    const args = { timeout: TIME_OUT }

    return retryIfTimeout(
      () => this.post('/client/api/v2/order/draft/submitWithCreatePayOrder', {
        couponId,
        couponCenterId,
        orderId: draftOrderId,
        shippingAddress,
        payMethod,
        checkoutToken
      }, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args)
    )
  }

  submitByHalfDraft({ orderId, couponCenterId, email, shippingAddress, eventID }) {
    const args = { timeout: TIME_OUT }
    const withEventHeaderArgs = {
      ...args,
      headers: {
        'X-fb-event-id': eventID || ''
      }
    }

    return retryIfTimeout(
      () => this.post('/client/api/v2/order/draft/submitByHalfDraft', {
        orderId,
        email,
        shippingAddress,
        couponCenterId,
        bizType: 'normal'
      }, withEventHeaderArgs)
    )
  }


  calDraftOrderAmount({ draftOrderId, couponId, couponCenterId, useCoin } = {}, anonymousUserToken) {
    const args = { timeout: TIME_OUT }

    return retryIfTimeout(() =>
      this.post('/client/api/v2/order/draft/calAmount', {
        orderId: draftOrderId,
        couponId,
        couponCenterId,
        canUseCoin: useCoin,
        calOrderAmountUseCouponCenterFlag: true
      }, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args).then(res => new CalcAmountModel(res))
    )
  }

  queryOrderHalfDraft = ({ orderId }) => {
    return retryIfTimeout(
      () => this.get(`/client/api/v2/order/halfDraft/${orderId}`)
      .then(res => {
        if (!res || res.payStatus === DRAFT_ORDER_PAY_STATUS.PAID) {
          let e = new Error('this order has been already paid!')
          e.errorCode = 'paid'
          throw e
        }

        return new DraftOrderModel(res)
      })
    )
  }

  convertOrderHalfDraft = (params) => {
    return retryIfTimeout(
      () => this.post(`/client/api/v2/order/halfDraft/convert`,
      {
        ...params
      })
    )
  }

  createGiftGalaxyAnonymousDraftOrder(lineItems = [], anonymousUserToken, shippingAddress) {
    return this.post('/client/api/v3/order/draft', {
      bizType: 'free_gift',
      buyMethod: 'direct',
      itemInfoList: lineItems.map(lineItem => ({
        skuId: lineItem.id,
        itemId: lineItem.itemId,
        quantity: lineItem.quantity
      })),
      shippingAddress
    }, {
      headers: {
        'X-access-token': anonymousUserToken
      }
    })
  }

  updateOrderUserInfo = orderInfoUpdateVO => {
    const { orderId, ...restRrderInfoUpdateVO } = orderInfoUpdateVO
    return this.put(`/client/api/v2/order/${orderId}`, {
      ...restRrderInfoUpdateVO
    })
  }

  _pay({ payMethod, subPayMethod, orderId, braintreeNonce, payPalOrderId, stripePayTokenId, klarnaAuthorizationToken, couponCenterId, shoppingCartId, billingAddress, referenceEmail, eventID, transactionVoucher } = {}, anonymousUserToken) {
    const args = { timeout: PAYMENT_TIME_OUT }
    const withEventHeaderArgs = {
      ...args,
      headers: {
        'X-fb-event-id': eventID || ''
      }
    }

    return retryIfTimeout(
      () => this.post('/client/api/v2/pay/gateway', {
        orderId,
        payMethod,
        subPayMethod,
        braintreeNonce,
        payPalOrderId,
        stripePayTokenId,
        klarnaAuthorizationToken,
        billingAddress,
        referenceEmail,
        couponCenterId,
        shoppingCartId,
        transactionVoucher,
        // this field means we charge customers at server side
        target: 'back-end'
      }, anonymousUserToken ? withAnonymousUserTokenAndEventIDHeader(anonymousUserToken, eventID, args) : withEventHeaderArgs)
    )
  }

  payWithStripe({ mainPayMethod, subPayMethod, orderId, tokenId, orderType, eventID, couponCenterId, shoppingCartId, transactionVoucher } = {}, anonymousUserToken) {
    return this._pay({
      payMethod: mainPayMethod,
      subPayMethod: subPayMethod,
      orderId,
      stripePayTokenId: tokenId,
      orderType,
      eventID,
      couponCenterId,
      shoppingCartId,
      transactionVoucher
    }, anonymousUserToken)
  }

  payWithPayPal({ orderId, mainPayMethod, subPayMethod, payPalOrderId, braintreeNonce, orderType, couponCenterId, shoppingCartId, referenceEmail, eventID } = {}, anonymousUserToken) {
    return this._pay({
      payMethod: mainPayMethod,
      subPayMethod: subPayMethod,
      orderId,
      braintreeNonce,
      payPalOrderId,
      orderType,
      referenceEmail,
      couponCenterId,
      shoppingCartId,
      eventID
    }, anonymousUserToken)
  }

  payWithBraintree({ mainPayMethod, subPayMethod, orderId, braintreeNonce, orderType, couponCenterId, shoppingCartId, referenceEmail, eventID } = {}, anonymousUserToken) {
    return this._pay({
      payMethod: mainPayMethod || PAYMENT_METHOD.BRAINTREE.id,
      subPayMethod,
      orderId,
      braintreeNonce,
      orderType,
      referenceEmail,
      couponCenterId,
      shoppingCartId,
      eventID
    }, anonymousUserToken)
  }

  payWithKlarna({ mainPayMethod, subPayMethod, orderId, tokenId, orderType, couponCenterId, shoppingCartId, eventID } = {}, anonymousUserToken) {
    return this._pay({
      payMethod: mainPayMethod,
      subPayMethod: subPayMethod,
      orderId,
      klarnaAuthorizationToken: tokenId,
      orderType,
      couponCenterId,
      shoppingCartId,
      eventID
    }, anonymousUserToken)
  }

  payWithCreditCard({ mainPayMethod, subPayMethod, orderId, braintreeNonce, orderType, couponCenterId, shoppingCartId, eventID } = {}, anonymousUserToken) {
    return this._pay({
      payMethod: mainPayMethod,
      subPayMethod: subPayMethod,
      orderId,
      braintreeNonce,
      orderType,
      couponCenterId,
      shoppingCartId,
      eventID
    }, anonymousUserToken)
  }

  bindCouponCode({ orderId, code, couponCenterId, shoppingCartId }, anonymousUserToken) {
    const args = { timeout: TIME_OUT }
    return retryIfTimeout(() =>
      this.post('/client/api/v3/order/draft/bindCouponCode', {
        couponCode: code,
        orderId,
        couponCenterId,
        shoppingCartId,
        calOrderAmountUseCouponCenterFlag: true
      }, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args).then(res => new CalcAmountModel(res))
    )
  }


  bindCouponCodeForHalfDraft({ orderId, code, couponCenterId, shoppingCartId }, anonymousUserToken) {
    const args = { timeout: TIME_OUT }
    return retryIfTimeout(() =>
      this.post('/client/api/v3/order/halfDraft/bindCouponCode', {
        couponCode: code,
        orderId,
        couponCenterId,
        shoppingCartId
      }, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args).then(res => new CalcAmountModel(res))
    )
  }


  autoBindCouponCode({ orderId, code, id, couponCenterId, shoppingCartId }, anonymousUserToken) {
    const args = { timeout: TIME_OUT }
    return retryIfTimeout(() =>
      this.post('/client/api/v2/order/draft/autoBindCouponCode', {
        couponId: id,
        couponCode: code,
        orderId,
        couponCenterId,
        shoppingCartId,
        calOrderAmountUseCouponCenterFlag: true
      }, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args).then(res => new CalcAmountModel(res))
    )
  }


  createKlarnaSession({ orderId }, anonymousUserToken) {
    const args = { timeout: TIME_OUT }

    return retryIfTimeout(() =>
      this.get(`/client/api/v2/pay/klarna/${orderId}/session`, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args)
    )
  }


  getKlarnaOrderDetail({ orderId, shippingAddress }, anonymousUserToken) {
    const args = { timeout: TIME_OUT }

    return retryIfTimeout(() =>
      this.post(`/client/api/v2/pay/klarna/${orderId}/detail`, {
        shippingAddress
      }, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args)
    )
  }

  updateOrderShippingAddress({ orderId, shippingAddress }, anonymousUserToken) {
    const args = { timeout: TIME_OUT }

    return retryIfTimeout(() =>
      this.put(`/client/api/v2/order/${orderId}`, {
        shippingAddress
      }, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args)
    )
  }


  updateOrderPhone({ orderId, phone }) {
    return retryIfTimeout(() =>
      this.post('/client/api/v2/order/completeShippingPhone', {
        orderId,
        phone
      })
    )
  }

  getCityAndStateByZipCode({ countryCode, zipCode }) {
    return this.get('/client/api/v1/addresses/state/city', {
      timeout: TIME_OUT,
      params: { countryCode, postalCode: zipCode },
    })
  }

  closeLastPendingPay(orderId) {
    return this.post(`/client/api/v2/order/lastPendingPay/${orderId}/close`)
  }

  cancelOrder(orderId, shoppingCartId) {
    return this.put(`/client/api/v2/order/${orderId}/cancel`, {
      shoppingCartId
    })
  }

  launchAfterOrderShare(orderId, anonymousUserToken) {
    const args = { timeout: TIME_OUT }

    return this.post(`/client/api/v1/acquisition`, {
      orderId
    }, anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args)
  }

  getAfterOrderShareInfo(acquisitionId) {
    return this.get(`/client/api/v1/acquisition?acquisitionId=${acquisitionId}`)
  }

  getAfterOrderShareHistory(acquisitionId) {
    return this.get(`/client/api/v1/acquisition/credit/history?acquisitionId=${acquisitionId}`)
  }

  orderBackToCart(orderId, shoppingCartId) {
    return this.post(`/client/api/v2/order/${orderId}/backToCart`, {
      shoppingCartId
    })
  }

  getPaymentMethods(countryCode, anonymousUserToken) {
    const args = { timeout: TIME_OUT }

    const defaultPaymentMethods = countryCode === 'US' ? DEFAULT_PAYMENT_METHODS[countryCode] : DEFAULT_PAYMENT_METHODS.OTHERS

    return retryIfTimeout(
      () => this.get(
        '/client/api/v2/noCache/pay/config',
        anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args
      )
    ).catch(() => defaultPaymentMethods)
  }

  getStripePaymentIntent = ({ orderId, countryCode, countryName, subPayMethod }, anonymousUserToken) => {
    const args = {
      timeout: TIME_OUT
    }

    return retryIfTimeout(
      () => this.get(
        `/client/api/v2/pay/stripe/${orderId}/paymentIntent`,
        {
          params: {
            countryCode,
            countryName,
            subPayMethod,
            timestamp: new Date().getTime()
          },
          ...anonymousUserToken ? withAnonymousUserTokenHeader(anonymousUserToken, args) : args
        }
      )
    )
  }

  fetchPaypalPaylaterMessaging = amount => {
    return retryIfTimeout(
      () => this.get(
        `/client/api/v2/pay/paypal/payLaterMessage?amount=${amount}`,
      )
    )
  }

  submitComment = params => {
    return this.post(`/content/api/v2/product/comment`, params)
  }

  fetchOrderCommentList = orderId => {
    return this.get(`/content/api/v2/product/comment/${orderId}/orderCommentList`)
  }

  fetchItemCommentInfo = orderItemId => {
    return this.get(`/content/api/v2/product/comment/${orderItemId}/itemCommentInfo`)
  }

  fetchItemCommentedInfo = orderItemId => {
    return this.get(`/content/api/v2/product/comment/${orderItemId}/itemCommentedInfo`)
  }

  fetchOrderCommentedList = orderId => {
    return this.get(`/content/api/v2/product/comment/${orderId}/orderCommentedList`)
  }

}

export {
  DRAFT_ORDER_PAY_STATUS
}
export default OrderService
