import BaseService from '@flamingo_tech/funkgo/src/base/BaseService'
import CartModel from '../models/CartModel'

class CartService extends BaseService {
  createDraftOrder(lineItems = [], { shippingAddress = null, bizType = 'normal' }) {
    return this.post('/client/api/v3/order/draft', {
      bizType,
      buyMethod: 'direct',
      itemInfoList: lineItems.map(lineItem => ({
        skuId: lineItem.id,
        itemId: lineItem.itemId,
        quantity: lineItem.quantity
      })),
      shippingAddress
    })
  }

  createAnonymousDraftOrder(lineItems = [], anonymousUserToken, couponCenterId, shippingAddress) {
    return this.post('/client/api/v3/order/draft', {
      bizType: 'normal',
      buyMethod: 'direct',
      itemInfoList: lineItems.map(lineItem => ({
        skuId: lineItem.id,
        itemId: lineItem.itemId,
        quantity: lineItem.quantity
      })),
      couponCenterId,
      shippingAddress,
      calOrderAmountUseCouponCenterFlag: true
    }, {
      headers: {
        'X-access-token': anonymousUserToken
      }
    })
  }


  createHalfDraftOrder(lineItems = [], couponCenterId) {
    return this.post('/client/api/v2/order/halfDraft', {
      bizType: 'normal',
      buyMethod: 'direct',
      itemInfoList: lineItems.map(lineItem => ({
        skuId: lineItem.id,
        itemId: lineItem.itemId,
        quantity: lineItem.quantity
      })),
      couponCenterId,
      calOrderAmountUseCouponCenterFlag: true
    })
  }

  createCoinDraftOrder(lineItems = []) {
    return this.post('/client/api/v3/order/draft', {
      bizType: 'coin_exchange',
      buyMethod: 'direct',
      itemInfoList: lineItems.map(lineItem => ({
        skuId: lineItem.id,
        quantity: lineItem.quantity
      }))
    })
  }


  applyAnonymousCart(legacyCart = {}, { couponCenterId }) {
    return this.post(`/client/api/v2/anonymousCarts/applyCart`, {
      itemList: (legacyCart.lineItems || []).map(lineItem => ({
        skuIdType: 'shopify',
        quantity: lineItem.quantity,
        skuId: lineItem.variantOriginalId
      })),
      couponCenterId,
      calcWithoutExpiredItems: true
    }).then(data => new CartModel(data))
  }

  getAnonymousCart(cartId, { couponCenterId }) {
    return this.post(`/client/api/v2/anonymousCarts/cartInfo`, {
      shoppingCartId: cartId,
      couponCenterId,
      calcWithoutExpiredItems: true
    }).then(data => new CartModel(data))
  }

  getAnonymousCartCount(cartId) {
    return this.get(`/client/api/v2/anonymousCarts/${cartId}/count`).then(data => ({
      shoppingCartId: cartId,
      ...data
    }))
  }

  addAnonymousCart(cartId, { lineItem = {}, appliedCoupon = {}, couponCenterId }, eventID, eventData) {
    return this.post(`/client/api/v2/anonymousCarts/addCart`, {
      shoppingCartId: cartId,
      item: {
        skuId: lineItem.id,
        quantity: lineItem.quantity,
        itemId: lineItem.itemId
      },
      couponCenterId,
      couponId: appliedCoupon && appliedCoupon.id,
      calcWithoutExpiredItems: true,
      updateFlag: true,
      eventData
    }, {
      headers: {
        'X-fb-event-id': eventID || ''
      }
    }).then(data => new CartModel(data))
  }


  addAnonymousCartWithoutCalc(cartId, { lineItem = {}, appliedCoupon = {}, couponCenterId }, eventID, eventData) {
    return this.post(`/client/api/v3/anonymousCarts/addCart`, {
      shoppingCartId: cartId,
      item: {
        skuId: lineItem.id,
        quantity: lineItem.quantity,
        itemId: lineItem.itemId
      },
      couponCenterId,
      couponId: appliedCoupon && appliedCoupon.id,
      calcWithoutExpiredItems: true,
      updateFlag: true,
      eventData
    }, {
      headers: {

        'X-fb-event-id': eventID || ''
      }
    }).then(data => ({
      itemQuantity: data.totalQuantity
    }))
  }


  getPreviewCart(cartId) {
    return this.get(`/client/api/v2/anonymousCarts/${cartId}/preview`)
  }


  updateAnonymousCart(cartId, { lineItem = {}, appliedCoupon = {}, couponCenterId }, eventID) {
    return this.put(`/client/api/v2/anonymousCarts/updateCart`, {
      shoppingCartId: cartId,
      item: {
        skuId: lineItem.id,
        quantity: lineItem.quantity,
        itemId: lineItem.itemId
      },
      couponCenterId,
      couponId: appliedCoupon && appliedCoupon.id,
      calcWithoutExpiredItems: true,
      updateFlag: true
    }, {
      headers: {
        'X-fb-event-id': eventID || ''
      }
    }).then(data => new CartModel(data))
  }

  updateSelectCart({ shoppingCartId, couponCenterId, selectedStatus, item }) {
    return this.put(`/client/api/v2/anonymousCarts/${shoppingCartId}/updateSelected`, {
      shoppingCartId,
      couponCenterId,
      selectedStatus,
      item
    }).then(data => new CartModel(data))
  }

  deleteAnonymousCart(cartId, { lineItems = [] }) {
    return this.del(`/client/api/v2/anonymousCarts/clearCart`, {
      data: {
        shoppingCartId: cartId,
        itemIdList: lineItems.map(lineItem => lineItem.itemId)
      }
    })
  }

  replaceAnonymousCart = (cartId, { lineItem = {}, appliedCoupon = {}, couponCenterId }, eventID) => {
    return this.put(`/client/api/v2/anonymousCarts/${cartId}/replaceSku`, {
      shoppingCartId: cartId,
      couponId: appliedCoupon && appliedCoupon.id,
      couponCenterId,
      calcWithoutExpiredItems: true,
      itemId: lineItem.itemId,
      skuId: lineItem.id
    }, {
      headers: {
        'X-fb-event-id': eventID || ''
      }
    }).then(data => new CartModel(data))
  }

  getLastPendingPay = () => {
    return this.get(`/client/api/v2/order/lastPendingPay`)
  }
}

export default CartService