import React, { useState } from 'react'
import ProductBasicInfoSection from '../Product/ProductBasicInfoSection'
import ProductFooterBar from '../Product/ProductFooterBar'
import { SizeChartModal } from '../Product/ProductSizeChartSection'
import { withDrawer } from '../../common/Drawer/Drawer'
import { withPlugin } from '@flamingo_tech/funkgo'

import styles from './ProductDrawer.module.css'

const ProductDrawer = props => {
  const scrollRef = React.createRef()

  const [showSizeChart, setShowSizeChart] = useState()
  const showSizeChartModal = () => {
    const { $track } = props

    $track.event('ProductPage', 'click_size_chart', props.handle)
    setShowSizeChart(true)
  }

  const closeSizeChartModal = () => setShowSizeChart(false)

  const {
    sizeChart = {},
    feature = {}
  } = props.meta || {}

  const { sizeChartTemplate } = props.product || {}

  return (
    <div className={styles.wrapper}>
      <div className={styles.content}>
        <div className={styles.header}>
          <div className={styles.close} onClick={props.onClose}>&#xe60e;</div>
        </div>
        <div className={styles.scrollContent} ref={scrollRef}>
          <ProductBasicInfoSection
            {...props}
            showSizeChartModal={showSizeChartModal}
            sizeChartTemplate={sizeChartTemplate}
            renderSizeLink={true}
            hideVideo={true}
            scrollRef={scrollRef}
          />
          {
            showSizeChart &&
            <SizeChartModal
              $i18n={props.$i18n}
              productTitle={props.title}
              sizeChart={sizeChart}
              sizeChartTemplate={sizeChartTemplate}
              feature={feature}
              onClose={closeSizeChartModal}
              className={styles.sizeChartPadding}
            />
          }
          <ProductFooterBar
            className={styles.fixedBar}
            {...props}
          />
        </div>
      </div>
    </div>
  )
}

export default withDrawer(withPlugin(ProductDrawer), { bodyClass: styles.bodyClass })