import React, { PureComponent } from 'react'

import queryString from 'query-string'
import AppStateContext from '../../hooks/AppStateContext'
import withCartAndPlugins from '../../hooks/withCartAndPlugins'

import StoreService from '../../services/StoreService'

import { rem as px2rem } from '../../utils/remUtils'
import { SITES } from '../../utils/siteUtils'

const USER_INFO_STORAGE_KEY = 'user_info'
const CHECK_USER_TOKEN_STORAGE_KEY = '_cu'
const SYNC_CART_FLAG_STORAGE_KEY = 'scf'
const SYNC_COUPON_CENTER_FLAG_STORAGE_KEY = 'sccf'
const PURCHASE_COUNT_STORAGE_KEY = 'purchase_count'

class RootShellContainer extends PureComponent {

  setRuntimeData = (key, value) => {
    const data = Object.assign({}, this.state.runtimeData, {
      [key]: value
    })

    this.setState({
      runtimeData: data
    })
  }

  state = {
    userInfo: {},
    globalPromotionMeta: this.props.metaInfo ? this.props.metaInfo.globalPromotionMeta : {},
    globalSettingMeta: this.props.metaInfo ? this.props.metaInfo.globalSettingMeta : {},
    regularCouponMeta: this.props.metaInfo ? this.props.metaInfo.regularCouponMeta : {},
    isMetaFetched: false,
    site: '',
    runtimeData: {},
    setRuntimeData: this.setRuntimeData
  }


  syncAssetsFlagStorage = this.props.$storage.create('saf')
  updateDeviceKeyStorage = this.props.$storage.create('udf')
  syncCartFlagStorage = this.props.$storage.create(SYNC_CART_FLAG_STORAGE_KEY)
  syncCouponCenterFlagStorage = this.props.$storage.create(SYNC_COUPON_CENTER_FLAG_STORAGE_KEY)

  /* -------------------------------------- */

  static fetchRootData({ $http } = {}) {
    const storeService = new StoreService($http)
    return storeService.fetchGlobalConfig()
  }

  fetchUserInfo() {
    const {
      $storage,
      $router,
      $user
    } = this.props

    const userInfoStorage = $storage.create(USER_INFO_STORAGE_KEY)
    const purchaseCountStorage = $storage.create(PURCHASE_COUNT_STORAGE_KEY)

    const userInfo = userInfoStorage.getItem({
      firstVisited: Date.now(),
      isVisited: false
    })

    const currentTime = Date.now()
    if (!userInfo.isReturning) {
      const isVisited = userInfo.isVisited
      const purchaseCount = purchaseCountStorage.getItem(0)
      const isPurchased = purchaseCount > 0
      const hasLeftEmail = $user.hasAnonymousLoginEmail()
      const hasLogin = $user.hasLogin()

      if (isPurchased) {
        userInfo.isReturning = true
        userInfo.returningLabel = 'is_purchased'
        userInfo.purchaseCount = purchaseCount
      } else if (hasLogin) {
        userInfo.isReturning = true
        userInfo.returningLabel = 'has_login'
      } else if (isVisited) {
        userInfo.isReturning = true
        userInfo.returningLabel = 'is_Visited'
      } else if (hasLeftEmail) {
        userInfo.isReturning = true
        userInfo.returningLabel = 'has_left_email'
      } else {
        userInfo.isReturning = false
        userInfo.returningLabel = ''
        userInfo.isVisited = true
      }
    } else if (!userInfo.returningLabel) {
      userInfo.returningLabel = 'exists' // data fix
    }

    userInfo.lastVisited = currentTime

    userInfoStorage.setItem(userInfo)

    let userSource = null
    if ($router.location.search) {
      const query = queryString.parse($router.location.search)
      if (query.utm_campaign && (query.utm_campaign.indexOf('_atc') > -1 || query.utm_campaign.indexOf('dpa') > -1)) {
        userSource = 'atc'
      }
    }

    this.setState({
      userInfo: {
        ...userInfo,
        userSource,
      },
    })
  }

  fetchSiteInfo() {
    const { $site } = this.props
    this.setState({
      site: $site.getSiteInfo()
    })
  }

  notifySiteUpdate = ({ site }) => {
    this.setState({
      site
    })
  }

  fetchGlobalConfig() {
    const {
      $http,
      $detector,
      $store,
      $router
    } = this.props

    const resolveMeta = ({
      globalPromotionMeta,
      globalSettingMeta,
      regularCouponMeta
    }) => {
      // only flamingo site joins promotions
      const flamingoStationsIds = SITES.FLAMINGO.internationalStations.map(station => station.id)

      if (flamingoStationsIds.indexOf(this.state.site.id) > -1) {
        const { theme, availablePromotions } = globalPromotionMeta

        if (theme && !$detector.isServer()) {
          window.document.documentElement.style.setProperty('--primary', theme)
        }

        // App新版本不领取大促券
        if (!$detector.isApp()) {
          $store.couponHub.highlight(availablePromotions)
        }

        this.setState({
          globalPromotionMeta
        })
      }

      this.setState({
        isMetaFetched: true,
        globalSettingMeta,
        regularCouponMeta,
      })

      if (!globalPromotionMeta.promoConfig) { // 没有大促时才领ci券
        const query = queryString.parse($router.location.search)
        if (query.ci) {
          const couponIdList = query.ci.split(',').map(id => ({
            id
          }))

          $store.couponHub.batchTake(couponIdList).then(() => {
            const { locale } = this.props.$site.getSiteInfo()

            if (locale !== 'en_US') {
              this.props.$toastSuccess(this.props.$i18n.transl('core.promotion.takenCouponSuccess'))
            }
          })
        } else if (query.cc) {
          const couponCodeList = query.cc.split(',').map(couponCode => ({
            code: couponCode
          }))

          $store.couponHub.takeCouponCodes(couponCodeList).then(() => {
            this.props.$toastSuccess(this.props.$i18n.transl('core.promotion.takenCouponSuccess'))
          })
        }
      }
    }

    if (typeof window !== 'undefined' && window.globalMetaInfo) {
      resolveMeta(window.globalMetaInfo)
      delete window.globalMetaInfo
    } else {
      const storeService = new StoreService($http)

      storeService.fetchGlobalConfig().then(resolveMeta)
    }

  }

  initDefaultStyleVariables() {
    const { $detector } = this.props

    // a workaround force add stick header top for m-site StoreHeader
    // , the 40px is defined at StoreHeader.module.css
    if (!$detector.isServer() && $detector.isWebStore()) {
      window.document.documentElement.style.setProperty('--sticky-header-top', px2rem(40))
    }
  }

  /* -------------------------------------- */
  ensureAppBridge() {
    const { $bridge } = this.props
    return $bridge && $bridge.isAppBridgesEnabled()
      ? $bridge
      : undefined
  }

  /* -------------------------------------- */
  handleAppReuseWebview = ({ url } = {}) => {
    const {
      $router,
      $user,
      $track
    } = this.props

    if ($router) {
      $user.forceRefreshUser().catch(() => {})
      $router.startReuseWebview(url)
    } else {
      document.location.href = url
    }

    // webview replace时解析utm参数用于埋点
    $track.setAppUtmInfo(url)
  }

  handleAppCallShare = () => {
    const $bridge = this.ensureAppBridge()
    if ($bridge) {
      $bridge.share().catch(() => { })
    }
  }

  handleAppLogout = () => {
    this.props.$user.logout().then(() => {
      if (typeof window !== 'undefined') {
        window.location.reload()
      }
    })
  }

  /* -------------------------------------- */

  // check user token
  // , if has no user token, then do nothing
  // , if has user token, then check if the token is valid (it may be expired if cross some day)
  ensureUserToken() {
    const { $storage, $user, $logger } = this.props

    const checkUserStorage = $storage.create(CHECK_USER_TOKEN_STORAGE_KEY, { strategy: 'SESSION' })
    const isChecked = checkUserStorage.getItem(false)

    if (!isChecked && $user) {
      $user.ensureUserToken().then(() => {
        checkUserStorage.setItem(true)
      }, err => {
        $logger.errorWithAction(err, 'ensureUserToken', 'RootShellContainer')
      })
    }
  }

  timer

  installSupport = () => {
    const isDesktop = this.props.$detector.isDesktop()

    if (isDesktop) {
      clearTimeout(this.timer)

      this.timer = setTimeout(() => {
        this.props.$bridge && this.props.$bridge.installIm()
          .then(() => {
            window.addEventListener('jsy_plugin_ready', evt => {
              // jsy_plugin_api - IM插件对外暴露的接口对象，也可以不用侦听事件，直接通过 window.JSY_PLUGIN_API 获取接口对象，但该属性只会在插件初始化完成后才会被赋值
              evt.detail.setOptions({
                closeButtonVisible: true, // 显示右上角关闭按钮
                launcherButtonVisible: isDesktop // 默认启动按钮
              })
            })
          })
      }, 0)
    }
  }

  syncAssetsForHistoryUser = () => {
    const hasLogin = this.props.$user.hasLogin()
    const syncAssetsFlag = this.syncAssetsFlagStorage.getItem(false)

    if (!hasLogin) {
      this.syncAssetsFlagStorage.setItem(true)
      return Promise.resolve()
    } else if (!syncAssetsFlag) {
      return this.props.$store.syncAssets()
        .then(() => {
          this.syncAssetsFlagStorage.setItem(true)
        })
    }

    return Promise.resolve()
  }

  updateDeviceKeyForHistoryUser = () => {
    const hasLogin = this.props.$user.hasLogin()
    const UDFlag = this.updateDeviceKeyStorage.getItem(false)

    if (!hasLogin) {
      this.updateDeviceKeyStorage.setItem(true)
      return Promise.resolve()
    } else if (!UDFlag) {
      return this.props.$store.syncUserDeviceKey()
        .then(() => {
          this.updateDeviceKeyStorage.setItem(true)
        })
    }

    return Promise.resolve()
  }

  // sync cartId and couponCenterId for app
  syncAppInfo() {
    const {
      $detector,
      $store,
      $logger,
      $track
    } = this.props

    if
    (
      $detector
      &&
      (
        ($detector.isIOS() && $detector.compareAppVersion({ targetVersion: '6.8.128' }))
        ||
        ($detector.isAndroid() && $detector.compareAppVersion({ targetVersion: '6.8.158' }))
      )
    ) {
      const $bridge = this.ensureAppBridge()
      if (!this.syncCartFlagStorage.getItem(false) || typeof $store.getCurrentCartId() !== 'string') {
        $bridge.getCartId().then(cartId => {
          if (typeof cartId === 'string') {
            $store.replaceDefaultCart(cartId).then(() => {
              this.syncCartFlagStorage.setItem(true)
              $track.event('App', 'webview_sync_cart_id', cartId)
            })
          }
        }).catch(err => {
          $logger.errorWithAction(err, 'syncCartId', 'RootShellContainer')
        })
      }

      if (!this.syncCouponCenterFlagStorage.getItem(false) || typeof $store.couponHub.getCouponCenterId() !== 'string') {
        $bridge.getCouponCenterId().then(couponCenterId => {
          if (typeof couponCenterId === 'string') {
            $store.couponHub.replaceCouponCenter(couponCenterId).then(() => {
              this.syncCouponCenterFlagStorage.setItem(true)
              $track.event('App', 'webview_sync_coupon_center_id', couponCenterId)
            })
          }
        }).catch(err => {
          $logger.errorWithAction(err, 'syncCouponCenterId', 'RootShellContainer')
        })
      }
    }
  }

  /* -------------------------------------- */
  componentDidMount() {
    this.fetchSiteInfo()
    this.fetchGlobalConfig()
    this.fetchUserInfo()
    this.initDefaultStyleVariables()
    this.ensureUserToken()
    this.installSupport()
    this.syncAssetsForHistoryUser()
    this.updateDeviceKeyForHistoryUser()
    this.syncAppInfo()

    const $bridge = this.ensureAppBridge()

    if ($bridge) {
      // for reuse scene, it will receive a broadcast from app called "replace"
      $bridge.addEventListenerToApp('replace', this.handleAppReuseWebview)
      $bridge.addEventListenerToApp('logout', this.handleAppLogout)
    }

    const { $site } = this.props

    if ($site) {
      $site.subscribe(this.notifySiteUpdate)
    }
  }


  componentWillUnmount() {
    const $bridge = this.ensureAppBridge()

    if ($bridge) {
      $bridge.removeEventListenerFromApp('replace', this.handleAppReuseWebview)
      $bridge.removeEventListenerFromApp('logout', this.handleAppLogout)
    }

    const { $site } = this.props

    if ($site) {
      $site.unsubscribe(this.notifySiteUpdate)
    }
  }

  /* -------------------------------------- */
  render() {
    return (
      <AppStateContext.Provider value={this.state}>
        {this.props.children}
      </AppStateContext.Provider>
    )
  }
}

export default withCartAndPlugins(RootShellContainer)

export {
  AppStateContext
}
