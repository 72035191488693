import FunkGo from '@flamingo_tech/funkgo'
import { MyLoadableComponent } from '@flamingo_tech/funkgo/src/MyLoadable'
import mockDomain from "./utils/urlConf"


import './App.css'
import pkg from '../package.json'

import router from './router'

import DeviceKeyPlugin from './plugins/DeviceKeyPlugin'
import TrackerPlugin from './plugins/TrackerPlugin'
import DialogPlugin from './plugins/DialogPlugin'
import BridgePlugin from './plugins/BridgePlugin'
import LifeCyclePlugin from './plugins/LifeCyclePlugin'
import RouterPlugin from './plugins/RouterPlugin'
import StorePlugin from './plugins/StorePlugin'
import UserPlugin from './plugins/UserPlugin'
import SitePlugin from './plugins/SitePlugin'
import I18nPlugin from './plugins/I18nPlugin'


import RootShellContainer from './containers/Shell/RootShellContainer'

import en from './lang/en.json'
import es from './lang/es.json'
import fr from './lang/fr.json'
import de from './lang/de.json'

// common components

const LoadingPage = MyLoadableComponent({
  loader: () => import('./components/common/LoadingPage/LoadingPage')
})
const Dialog = MyLoadableComponent({
  loader: () => import('./components/common/Dialog/Dialog')
})
const Toast = MyLoadableComponent({
  loader: () => import('./components/common/Toast/Toast')
})
const Loading = MyLoadableComponent({
  loader: () => import('./components/common/LoadingPage/LoadingModal')
})

const ErrorPageContainer = MyLoadableComponent({
  loader: () => import('./containers/Error/ErrorPageContainer')
})
const NotFoundPageContainer = MyLoadableComponent({
  loader: () => import('./containers/Error/NotFoundPageContainer')
})

/* ----------------------------------- */

const funkGoApp = new FunkGo({
  info: {
    name: pkg.name,
    version: pkg.version,
    domain: 'flamingo.shop',
    scheme: 'flamingo://flamingo.shop/',

    // if any url defined as absolute link as known domain, it will covert as relative path for spa
    knownDomainReg: /^(?:https?:)?\/\/((?:m\d?|www|s|p|uk|us|fr|de|ca|uat|test)\.)?flamingo\.shop(.*)/,
    siteName: 'Flamingals by Flamingo Shop',
    siteTitle: `Quality Women's Jeans for Less`,
    siteDesc: `— Up to 80% off on various styles, cuts, and fits. Our stretch fabric ensures a perfect fit for every size. Embrace your multifaceted roles with Flamingals' stylish and comfortable jeans. BE YOU. BE MORE. Shop now for unbeatable deals and free shipping over $69.`,
    shareImage: '//s3-us-west-1.amazonaws.com/flamingos3/20190517/images/c4833ac633c743fd94d5dfbe16b3ff50.png',
    fbAppId: process.env.REACT_APP_FB_APP_ID,
    googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
    appleClientId: process.env.REACT_APP_APPLE_CLIENT_ID,
  },
  ssr: {
    // ua object is from 'ua-parser-js', https://www.npmjs.com/package/ua-parser-js
    getEnvKey: ({ ua, hostname }) => {
      // different env may have it own ssr cache, so this method detect ua and host name to give an independent env key
      // and ssr server will read the env key to create different cache
      // so that if give "desktop", "mobile", it will have different content from ssr server

      // case 1 - return false to disable ssr from other sites
      if (hostname.indexOf('flamingo') <= -1 && hostname !== 'localhost') {
        return false // return false to disable ssr
      }

      // case 2 - return "app" string to create independent env for app
      if (ua.os.name === 'iOS' || ua.os.name === 'Android') {
        if (ua.ua.indexOf('Flamingo') > -1) {
          return 'app'
        }
      }

      // case 3 - return "mobile", "table" or "default" to create another three "env"
      return ua.device.type || 'default'

      // if someday "iphoneix" want to enable ssr, feel free to return another key like "iphoneix.mobile", "iphoneix.default"
    }
  },
  router: {
    routes: router.routes
  },
  http: {
    timeout: process.env.REACT_APP_API_TIMEOUT,
    baseURL: mockDomain ? mockDomain : process.env.REACT_APP_API_ENDPOINT,
    baseStaticURL: process.env.REACT_APP_STATIC_API_ENDPOINT,
    baseDynamicURL: mockDomain ? mockDomain : process.env.REACT_APP_DYNAMIC_API_ENDPOINT,
    serverBaseURL: process.env.REACT_APP_API_SSR_ENDPOINT,
    shopifyTimeout: process.env.REACT_APP_SHOPIFY_TIMEOUT,
    shopifyBaseURL: process.env.REACT_APP_SHOPIFY_ENDPOINT,
    shopifyToken: process.env.REACT_APP_SHOPIFY_ACCESS_TOKEN,
  },
  pluginsFactory: {
    deviceKey: DeviceKeyPlugin,
    tracker: TrackerPlugin,
    dialog: DialogPlugin,
    bridge: BridgePlugin,
    lifeCycle: LifeCyclePlugin,
    router: RouterPlugin,
    store: StorePlugin,
    user: UserPlugin,
    site: SitePlugin,
    i18n: I18nPlugin
  },
  plugins: {
    deviceKey: {},
    bridge: {
      braintreeId: process.env.REACT_APP_BRAINTREE_ID,
      payPalId: process.env.REACT_APP_PAYPAL_ID,
      stripeId: process.env.REACT_APP_STRIPE_ID,
      checkoutId: process.env.REACT_APP_CHECKOUT_ID,
      googleMapId: process.env.REACT_APP_GOOGLE_MAP_ID,
      zendeskId: process.env.REACT_APP_ZENDESK_ID,
      klarnaOneSiteMessageIds: {
        eu: process.env.REACT_APP_KLARNA_ONE_SITE_MESSAGE_ID_EU,
        us: process.env.REACT_APP_KLARNA_ONE_SITE_MESSAGE_ID_US,
      }
    },
    tracker: {
      ga: process.env.REACT_APP_GA,
      ga4: process.env.REACT_APP_GA4,
      gtm: process.env.REACT_APP_GTM,
      googleAds: process.env.REACT_APP_GOOGLE_ADS,
      criteo: process.env.REACT_APP_GOOGLE_CRITEO,
      pixels: {
        flamingo: {
          default: process.env.REACT_APP_FB_PIXEL,
          gb: process.env.REACT_APP_FB_PIXEL_GB,
          fr: process.env.REACT_APP_FB_PIXEL_FR,
          de: process.env.REACT_APP_FB_PIXEL_DE,
          es: process.env.REACT_APP_FB_PIXEL_ES
        },
        iphoenix: {
          default: process.env.REACT_APP_FB_PIXEL_IPHOENIX
        }
      },
      googleAdsConversionMap: {
        googleAdsConversionPurchaseId: process.env.REACT_APP_GOOGLE_ADS_PURCHASE_CONVERSION_ID,
        googleAdsConversionViewProductId: process.env.REACT_APP_GOOGLE_ADS_VIEW_PRODUCT_CONVERSION_ID,
        googleAdsConversionAddToCartId: process.env.REACT_APP_GOOGLE_ADS_ADD_TO_CART_CONVERSION_ID,
        googleAdsConversionBeginCheckoutId: process.env.REACT_APP_GOOGLE_ADS_BEGIN_CHECKOUT_CONVERSION_ID
      },
      tiktok: process.env.REACT_APP_TIKTOK_PIXEL,
      snapchat: process.env.REACT_APP_SNAPCHAT_PIXEL
    },
    dialog: {
      Dialog: Dialog,
      Toast: Toast,
      Loading: Loading
    },
    lifeCycle: {
      AppShell: RootShellContainer,
      Error: ErrorPageContainer,
      NotFound: NotFoundPageContainer,
      Loading: LoadingPage
    },
    user: {},
    site: {},
    store: {},
    i18n: {
      resources: {
        en,
        es,
        fr,
        de
      }
    }
  },
})

funkGoApp.start()

export default funkGoApp
