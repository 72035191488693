import React, { useState, useEffect } from 'react'
import { withModal } from '../../common/Modal/Modal'
import { withPlugin } from '@flamingo_tech/funkgo'

import Image from '../../common/Image/Image'
import Button from '../../common/Button/Button'

import useFn from '../../../hooks/useFn'

import styles from './FreeGiftPCModal.module.css'

const FreeGiftPCModal = withModal(withPlugin(props => {
  const [seconds, setSeconds] = useState(props.seconds)

  const wrapperClose = useFn(props.onClose)

  useEffect(() => {
    let timer

    if (typeof seconds === 'number') {
      timer = setTimeout(() => {
        setSeconds(x => x - 1)
      }, 1000)
    }

    return () => {
      if (seconds === 1) {
        wrapperClose()
      }
      clearTimeout(timer)
    }
  }, [seconds, wrapperClose])

  const trackFn = useFn(() => {
    props.$track.event({ category: 'Wheel', action: `view_free_gift` })
  })

  const handleClose = () => {
    props.$track.event({ category: 'Wheel', action: `close_free_gift`, nonInteraction: false })
    props.onClose()
  }

  useEffect(() => {
    trackFn()
  }, [trackFn])

  return (
    <div className={styles.wrapper}>
      <div className={styles.close} onClick={handleClose}>&#xe60e;</div>
      <div className={styles.giftImage} />
      <div className={styles.roof}>{props.$i18n.transl('core.promotion.wheel.roof')}!</div>
      <div className={styles.freeGiftImage}>
        <Image src={props.productImage} tiny />
      </div>
      <div className={styles.title}>{props.$i18n.transl('core.promotion.freeGift.youHaveAFreeGift')}</div>
      <Button className={styles.btn} onClick={() => props.onTake({ skuId: props.skuId })}>
        {props.$i18n.transl('core.promotion.freeGift.claimIt')}
        {
          !!seconds && <span className={styles.seconds}> ({seconds}s)</span>
        }
      </Button>
    </div>
  )
}), { disableCoverClick: true })

export default FreeGiftPCModal