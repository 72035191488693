
import LineItemModel from './LineItemModel'
import UserAddressModel from '../common/UserAddressModel'

class OrderModel {
  constructor(data) {
    Object.assign(this, {
      // B: same as shopify order model

      // The sum of all the prices of all the items in the checkout, taxes and discounts included.
      totalPrice: data.totalFee,

      // 	The sum of all the taxes applied to the line items and shipping lines in the checkout.
      totalTax: 0,

      // Price of the checkout before shipping and taxes.
      subTotalPrice: data.productFee,

      // The sum of all the prices of all the items in the checkout.
      // Taxes, shipping and discounts excluded.
      lineItemsSubtotalPrice: data.productFee,

      currencyCode: data.currencyCode,
      currencySymbol: data.currencySymbol,
      displayTotalPrice: data.displayTotalPrice,
      displayProductFee: data.displayProductFee,
      displayDiscountFee: data.displayDiscountFee,
      displayTotalDiscounts: data.displayTotalDiscounts,
      displayFreight: data.displayFreight,

      // TODO
      orderStatusUrl: '',

      lineItems: (data.list || []).map(item => new LineItemModel(item)),
      // E: same as shopify order model


      // B: our own fields
      id: data.orderId,
      typeName: data.typeName,
      totalDiscount: data.discountFee,
      totalDiscounts: data.totalDiscounts, // coupon, coins included
      totalShipping: data.freight,
      totalQuantity: data.totalQuantity,
      payStatus: data.payStatus,

      couponId: data.couponId,
      // E: our own fields

      shippingAddress: data.shippingAddress ? new UserAddressModel(data.shippingAddress) : null,
      email: data.email

    })
  }
}

export default OrderModel
